import {TableCell} from 'shared/ui/Table/Table';
import cn from 'classnames';

import {formatCurrency} from 'shared/helpers/formatHelper';
import {NO_TAX_TITLE, TAX_TITLE} from 'shared/constants';

import * as S from './style';

export interface SummaryProps {
    sums: {
        sum?: number;
        sum_tax?: number;
        total_sum?: number;
    };
    agreementOnRevision?: boolean;
    unclosed?: boolean;
}

export function Summary({
    sums: {sum, sum_tax, total_sum},
    agreementOnRevision,
    unclosed,
}: SummaryProps) {
    const hasVAT = !!sum_tax && sum_tax > 0;
    const inactiveClassName = cn({inactiveText: unclosed});
    return (
        <>
            <S.FinalSumString className={inactiveClassName}>
                <TableCell colspan={6}>
                    <S.Name>{`Итого${unclosed ? ' осталось' : ''} без НДС:`}</S.Name>
                </TableCell>
                <TableCell>
                    <S.Value className={inactiveClassName}>
                        {formatCurrency((sum || 0).toString())}
                    </S.Value>
                </TableCell>
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={6}>
                    <S.Name className={inactiveClassName}>
                        {hasVAT ? TAX_TITLE : NO_TAX_TITLE}
                    </S.Name>
                </TableCell>
                {hasVAT && (
                    <TableCell>
                        <S.Value className={inactiveClassName}>
                            {formatCurrency((sum_tax || 0).toString())}
                        </S.Value>
                    </TableCell>
                )}
            </S.FinalSumString>
            <S.FinalSumString>
                <TableCell colspan={6}>
                    <S.Name className={inactiveClassName}>{`Итого${
                        unclosed ? ' осталось' : ''
                    } по ведомости${hasVAT ? ' с НДС' : ''}:`}</S.Name>
                </TableCell>
                <TableCell>
                    <S.Value className={inactiveClassName}>
                        {formatCurrency((total_sum || 0).toString())}
                    </S.Value>
                </TableCell>
            </S.FinalSumString>
        </>
    );
}
