import {useGate, useStore} from 'effector-react';

import {TableBase} from 'Entities/TableBase';
import {TableHeader} from 'Features/TableHeader';
import {
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';
import {IColumn} from 'shared/types/tableTypes';
import {IPaginationData} from 'shared/types/commonTypes';

import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';

import {AgreementTableContent} from './AgreementTableContent';
import {
    $additionalAgreementsTable,
    AdditionalAgreementsGate,
    setPage,
    setPageSize,
    toggleOpenDetails,
} from '../../model';

interface AdditionalAgreementsTableProps {
    columns: IColumn<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumn<IAgreementAddition>[];
}

export function AdditionalAgreementsTable({columns, innerColumns}: AdditionalAgreementsTableProps) {
    const {agreements, isLoading, openedIds, tableParams} = useStore($additionalAgreementsTable);

    const paginationData: IPaginationData = {
        page: tableParams.page,
        pageSize: tableParams.page_size,
        total: tableParams.total,
        isLoading,
        setPageSize,
        setPage,
    };
    useGate(AdditionalAgreementsGate);
    return (
        <TableBase minTableWidth="1200px" paginationData={paginationData}>
            <TableHeader<IToggledAdditionalAgreementListItem> columns={columns} />
            {isLoading ? (
                <TableBodySkeleton<IToggledAdditionalAgreementListItem>
                    pageSize={10}
                    columns={columns}
                    height="52px"
                    dense
                />
            ) : (
                <tbody>
                    <AgreementTableContent
                        columns={columns}
                        innerColumns={innerColumns}
                        agreements={agreements}
                        openedIds={openedIds}
                        toggleOpenDetails={toggleOpenDetails}
                    />
                </tbody>
            )}
        </TableBase>
    );
}

/*<S.TableBorderWrap>
    <Table tableLayout>
        <TableHeader<IToggledAdditionalAgreementListItem> columns={columns} />
        <tbody>
        {isLoading ? (
            <AgreementsContentSkeleton columns={columns} />
        ) : (
            <AgreementTableContent
                columns={columns}
                innerColumns={innerColumns}
                agreements={agreements}
                openedIds={openedIds}
                toggleOpenDetails={toggleOpenDetails}
            />
        )}
        </tbody>
    </Table>
    <Pagination total={10} setPageSize={() => 1} setPage={() => 1} />
</S.TableBorderWrap>*/
