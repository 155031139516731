import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {TableData, Typography} from '@beeline/design-system-react';
import cn from 'classnames';

import {CoefficientUnit} from 'Entities/CoefficientUnit';
import {SealedPriceData} from 'Entities/TableCells/SealedPriceData';
import {CommentCell} from 'Entities/TableCells/CommentCell';
import {CompletedWorksActionLabel} from 'Entities/CompletedWorksActionLabel';
import {DocumentActionCell} from 'Features/DocumentActionCell/ui/DocumentActionCell';
import {PositionCell} from 'pages/VirConstructor/SpecificationRow/Cells/PositionCell';
import {NameCell} from 'pages/VirConstructor/SpecificationRow/Cells/NameCell';
import {SumCell} from 'pages/VirConstructor/SpecificationRow/Cells/SumCell';
import {
    ICompletedWorkSpecification,
    TCompletedWorkSpecificationMode,
} from 'shared/types/completedWorksTypes';
import {TableStringSelected} from 'shared/ui/Table/Table';
import {IStatus} from 'shared/types/statusTypes';

import {AttachmentRow} from './AttachmentRow/AttachmentRow';
import {
    $completedWorksWorkflowStore,
    $loadingAttachmentId,
    actionButtonClicked,
} from '../../../../model';
import {handleDownloadAttachment} from '../../../../model/helpers';

interface SpecificationProps {
    specification: ICompletedWorkSpecification;
    mode: TCompletedWorkSpecificationMode;
    status: IStatus;
    index?: number;
    inactive?: boolean;
}

export const SpecificationRow = ({
    specification,
    mode,
    index,
    status,
    inactive = false,
}: SpecificationProps) => {
    const [showAttachmentRow, setShowAttachmentRow] = useState(false);
    const {taskData} = useStore($completedWorksWorkflowStore);
    const loadingAttachmentId = useStore($loadingAttachmentId);
    const {
        category_name,
        coefficient,
        coefficient_ams,
        coefficient_foundation,
        coefficient_remote_territory,
        completed_work_specification_attachments,
        completed_work_specification_comments,
        is_ams,
        is_foundation,
        id,
        link_document_id,
        name,
        note,
        number,
        price,
        unit,
        work_coefficients,
        sum,
        quantity_completed,
        quantity_full,
        quantity_max,
        result,
        vvr_number,
        work_detail,
        work_specification_attachments,
        work_specification_comments,
        work_specification_approval,
        work_specification_approval_total,
    } = specification;
    const is_agreed_price = false;

    const toggleAttachmentRow = () => {
        setShowAttachmentRow((prevState) => !prevState);
    };
    const taskStatus = taskData[0]?.status ?? 0;
    const showActionCell =
        mode === 'current' &&
        work_specification_approval !== undefined &&
        work_specification_approval_total !== undefined;

    const hasComments = !!completed_work_specification_comments?.length;
    const commentCellShown =
        !!work_specification_comments?.length ||
        hasComments ||
        !!work_specification_attachments?.length ||
        !!completed_work_specification_attachments?.length;

    const quantity = mode === 'available' ? quantity_max : quantity_completed;
    const attachmentCount = completed_work_specification_attachments?.length ?? 0;
    const appliedCoefficientNumbers = work_coefficients
        ?.filter((el) => !el.is_manual && el.number)
        .map((el) => el.number)
        .join(', ');

    const inactiveClassName = cn({inactiveText: inactive});

    return (
        <>
            <TableStringSelected noBorder={false} className={inactiveClassName}>
                <TableData>{index && <Typography variant="body3">{index}</Typography>}</TableData>
                <PositionCell
                    number={number}
                    coefficient={+coefficient}
                    appliedCoefficientNumbers={appliedCoefficientNumbers}
                />
                <NameCell name={name} note={note ?? undefined} className={inactiveClassName} />
                <TableData alignRight>
                    <Typography variant="body3" className={inactiveClassName}>
                        {quantity}
                    </Typography>
                </TableData>
                <TableData className={inactiveClassName}>
                    <SealedPriceData isAgreedPrice={is_agreed_price} price={price} unit={unit} />
                </TableData>
                <TableData>
                    <CoefficientUnit
                        coefficients={work_coefficients}
                        territoryCoefficient={+coefficient_remote_territory}
                        amsCoefficient={is_ams ? +coefficient_ams : undefined}
                        foundationCoefficient={is_foundation ? +coefficient_foundation : undefined}
                        totalCoefficient={+coefficient}
                        className={inactiveClassName}
                    />
                </TableData>
                <SumCell sum={+sum} />
                <TableData>
                    <CompletedWorksActionLabel
                        action={result}
                        completelyClosed={quantity_completed === quantity_full}
                        mode={mode}
                        completedWorksId={link_document_id}
                        completedWorksNumber={vvr_number}
                    />
                </TableData>

                <CommentCell
                    attachmentCount={attachmentCount}
                    onClick={toggleAttachmentRow}
                    hasComments={hasComments}
                    showButton={commentCellShown}
                />

                <TableData>
                    {showActionCell && (
                        <DocumentActionCell
                            data={{
                                id,
                                work_specification_approval,
                                work_specification_approval_total,
                            }}
                            onClick={actionButtonClicked}
                            isEditable={status.is_editable}
                            taskStatus={taskStatus}
                        />
                    )}
                </TableData>
            </TableStringSelected>
            {showAttachmentRow && (
                <AttachmentRow
                    commentList={completed_work_specification_comments}
                    attachmentList={completed_work_specification_attachments}
                    parentCommentList={work_specification_comments}
                    parentAttachmentList={work_specification_attachments}
                    handleDownloadAttachment={handleDownloadAttachment}
                    loadingAttachmentId={loadingAttachmentId}
                    jobCategory={work_detail}
                    jobCategoryDescription={category_name}
                />
            )}
        </>
    );
};
