import {Link} from 'react-router-dom';
import React from 'react';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatCurrency, formatDateHours} from 'shared/helpers/formatHelper';
import {createLink} from 'shared/helpers/links';
import {IColumn} from 'shared/types/tableTypes';
import {
    IColumnCompletedWorksListItem,
    IToggledCompletedListItem,
} from 'shared/types/completedWorksTypes';
import * as S from './style';

export const columns: IColumn<IToggledCompletedListItem>[] = [
    {
        name: 'cw_toggle',
        label: '',
        render: ({isOpen, vvrs}) => (
            <S.Cell>
                {vvrs && vvrs.length > 0 && (
                    <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
                )}
            </S.Cell>
        ),
        width: '50px',
    },
    {
        name: 'completed_works_number',
        render: (item) => <Link to={createLink(2, item.id)}>{`ДС ${item.number_agreement}`}</Link>,
        width: '112px',
    },
    {
        name: 'empty_cell_1',
        width: '112px',
    },
    {
        name: 'main_contractor',
        width: '112px',
    },
    {
        name: 'empty_cell_3',
        width: '112px',
    },
    {
        name: 'empty_cell_4',
        width: '112px',
    },
];

export const innerColumns: IColumn<IColumnCompletedWorksListItem>[] = [
    {name: 'toggle', width: '50px'},
    {
        name: 'completed_works_number',
        label: '№ ВВР',
        render: ({id, number, hasPermission}) => {
            const name = `ВВР ${number}`;
            return hasPermission ? <Link to={`/completed-works/${id}`}>{name}</Link> : name;
        },
        width: '200px',
    },
    {
        name: 'price',
        label: 'Стоимость работ, ₽',
        render: ({sum}) => <span>{formatCurrency(sum)}</span>,
        width: '112px',
    },
    {
        name: 'main_contractor',
        label: 'ГПО',
        render: ({gpo_short_name}) => <span>{gpo_short_name}</span>,
        width: '150px',
    },
    {
        name: 'purchasing_event_number',
        label: 'Дата создания',
        render: ({created_at}) => <span>{formatDateHours(created_at)}</span>,
        width: '130px',
    },
    {
        name: 'aprroved_date',
        label: 'Дата согласования',
        render: ({approved_at}) => <span>{formatDateHours(approved_at)}</span>,
        width: '130px',
    },
    {
        name: 'completed_works_status',
        label: 'Статус',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        type={labelVariant(item.status?.token)}
                        iconName={Icons.Dot}
                        variant="outline"
                        title={item.status?.name}
                    />
                )
            );
        },
        width: '205px',
    },
];
