import React from 'react';
import {useStore} from 'effector-react';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import {
    $createVirStartPageData,
    $createVirStartPageOptions,
    selectContract,
} from 'pages/VirStart/model';
import * as S from './styled';

export function ContractSelect() {
    const {contractOptions, formErrors, loadingCatalogContracts} = useStore(
        $createVirStartPageOptions,
    );
    const {selectedContract} = useStore($createVirStartPageData);

    return (
        <S.SelectWrapper className="col-3">
            <Select
                value={selectedContract}
                disabled={loadingCatalogContracts}
                valueLabel="contract_number"
                handleEdit={selectContract}
                title="Договор"
                error={formErrors?.contract}
                hint={formErrors?.contract ? 'Укажите договор' : ''}
                id="AgreementConstructorContractSelect"
            >
                {contractOptions.map((item) => (
                    <SelectItem
                        multiple={false}
                        selected={selectedContract}
                        key={item.framework_contract_id}
                        value={item}
                        id={item.contract_number}
                    >
                        {item.contract_number}
                    </SelectItem>
                ))}
            </Select>
        </S.SelectWrapper>
    );
}
