import styled from 'styled-components/macro';

export const FinalSumString = styled.tr`
    td {
        height: auto;
        padding: 6px 0 6px 0;
    }

    border: none;
    font-weight: 500;
`;

export const Name = styled.div`
    text-align: right;
    padding-right: 16px;
`;

export const Value = styled.div`
    max-width: 132px;
    text-align: right;
    padding-right: 16px;
`;
