import styled, {css} from 'styled-components';

import Select from 'shared/ui/Select/Select';

export const StyledSpan = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export const StyledSelect = styled(Select)<{full?: boolean}>`
    ${(p) =>
        p.full &&
        css`
            &:first-child {
                width: 148px;
                max-width: 148px;
            }
        `}
`;

export const RowWrapper = styled.div`
    width: 1336px;
    display: table;
`;
