import React from 'react';
import {Link} from 'react-router-dom';
import {Icon, Label} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TooltipedSpan} from 'shared/ui/TooltipedSpan';
import {IColumn} from 'shared/types/tableTypes';
import {
    IToggledAdditionalAgreementListItem,
    IAgreementAddition,
} from 'shared/types/additionalAgreementsTypes';
import {formatDate} from 'shared/helpers/formatHelper';
import {convertTokenToStatus} from 'shared/helpers/label';
import {StyledSpan} from 'shared/styles/commonStyle';
import {AGREEMENT_ADDITION_TITLE, AGREEMENT_TERMINATION_TITLE} from 'shared/constants';

import * as S from './style';

export const columns: IColumn<IToggledAdditionalAgreementListItem>[] = [
    {
        name: 'Toggle',
        label: '',
        render: ({addendum, isOpen}) => (
            <S.Cell>
                {addendum && addendum.length > 0 && (
                    <Icon iconName={isOpen ? Icons.NavArrowUp : Icons.NavArrowDown} size="medium" />
                )}
            </S.Cell>
        ),
        width: '50px',
        minWidth: '50px',
    },
    {
        name: 'number_agreement',
        label: 'Номер ДС',
        render: (item) => (
            <Link to={`/additional-agreements/${item?.id}`}>
                <StyledSpan lineCount={1}>{item?.number_agreement || '—'}</StyledSpan>
            </Link>
        ),
        width: '112px',
        minWidth: '112px',
    },
    {
        name: 'creation_date',
        label: 'Дата создания',
        render: ({created_at}) => <span>{formatDate(created_at)}</span>,
        width: '131px',
        minWidth: '131px',
    },
    {
        name: 'number_addendum',
        label: 'Доп.',
        render: ({addendum}) => (
            <S.Cell>
                <S.Value>
                    {
                        <Icon
                            iconName={addendum?.length ? Icons.Check : Icons.Remove}
                            size="medium"
                        />
                    }
                </S.Value>
            </S.Cell>
        ),
        width: '80px',
        minWidth: '80px',
    },
    {
        name: 'project_ext_id',
        label: 'Проект',
        render: (item) => (
            <Link to={`/projects/${item?.project_id}`}>{item?.project_ext_id || '—'}</Link>
        ),
        width: '100px',
        minWidth: '100px',
    },
    {
        name: 'bs_name',
        label: 'Базовая станция',
        render: (item) => (
            <StyledSpan lineCount={2}>
                {item?.bs_number} {item?.bs_name}
            </StyledSpan>
        ),
        width: '196px',
        minWidth: '196px',
    },
    {
        name: 'bs_gfk',
        label: 'Код ГФК',
        render: (item) => <span>{item?.bs_gfk}</span>,
        width: '138px',
        minWidth: '138px',
    },
    {
        name: 'work_type',
        label: 'Тип работ',
        render: ({work_types}) => (
            <TooltipedSpan title={work_types} lineCount={2}>
                {work_types}
            </TooltipedSpan>
        ),
        width: '212px',
        minWidth: '212px',
    },
    {
        name: 'purchasing_event_number',
        label: '№ ЗМ',
        render: (item) => <span>{item?.purchasing_event_number}</span>,
        width: '90px',
        minWidth: '90px',
    },
    {
        name: 'purchase_lots_name',
        label: 'Лот',
        render: (item) => <StyledSpan lineCount={2}>{item?.purchase_lots_name}</StyledSpan>,
        width: '60px',
        minWidth: '60px',
    },
    {
        name: 'implementer_name',
        label: 'ГПО',
        render: (item) => <StyledSpan lineCount={2}>{item?.implementer_name}</StyledSpan>,
        width: '212px',
        minWidth: '212px',
    },
    {
        name: 'contract_number',
        label: 'Договор',
        render: (item) => <span>{item?.contract_number}</span>,
        width: '140px',
        minWidth: '140px',
    },
    {
        name: 'approving_date',
        label: 'Дата согласования',
        render: ({approved_at}) => <span>{formatDate(approved_at)}</span>,
        width: '131px',
        minWidth: '131px',
    },
    {
        name: 'signing_date',
        label: 'Дата подписания',
        render: ({signing_date}) => <span>{formatDate(signing_date)}</span>,
        width: '131px',
        minWidth: '131px',
    },
    {
        name: 'status',
        label: 'Статус',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        title={item.status.name}
                        type={convertTokenToStatus(item.status.token)}
                    />
                )
            );
        },
        width: '205px',
    },
];

export const innerColumns: IColumn<IAgreementAddition>[] = [
    {name: 'addendum_toggle'},
    {
        name: 'number_addendum',
        render: ({id, number_addendum, is_termination, agreement_id}) => {
            const isTermination = !!agreement_id && is_termination;
            const linkText = isTermination
                ? AGREEMENT_TERMINATION_TITLE
                : `${AGREEMENT_ADDITION_TITLE} ${number_addendum ?? '—'}`;
            return (
                <Link to={`/additional-agreements/${id}`}>
                    <StyledSpan lineCount={2}>{linkText}</StyledSpan>
                </Link>
            );
        },
        width: '80px',
    },
    {
        name: 'addendum_creation_date',
        label: 'Дата создания',
        render: ({created_at}) => <StyledSpan lineCount={2}>{formatDate(created_at)}</StyledSpan>,
        width: '131px',
    },
    {name: 'project_pos_code'},
    {name: 'bs_name'},
    {name: 'bs_gfk'},
    {name: 'bs_gfk2'},
    {
        name: 'work_type',
        label: 'Тип работ',
        render: ({work_types}) => (
            <TooltipedSpan title={work_types} lineCount={2}>
                {work_types}
            </TooltipedSpan>
        ),
        width: '212px',
        minWidth: '212px',
    },
    {
        name: 'purchasing_event_number',
        width: '90px',
    },
    {
        name: 'purchase_lots_name',
        width: '60px',
    },
    {
        name: 'implementer_name',
        width: '212px',
    },
    {
        name: 'framework_contract_number',
        width: '140px',
    },
    {
        name: 'addendum_approving_date',
        label: 'Дата согласования',
        render: ({approved_at}) => <span>{formatDate(approved_at)}</span>,
        width: '131px',
    },
    {
        name: 'addendum_signing_date',
        label: 'Дата подписания',
        render: ({signing_date}) => <span>{formatDate(signing_date)}</span>,
        width: '131px',
    },
    {
        name: 'status',
        render: (item) => {
            return (
                item.status && (
                    <Label
                        title={item.status.name}
                        type={convertTokenToStatus(item.status.token)}
                    />
                )
            );
        },
        width: '205px',
    },
];
