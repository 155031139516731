import React from 'react';
import {useNavigate} from 'react-router-dom';
import {TableRow} from '@beeline/design-system-react';

import {CustomTableData, InnerTableRow} from 'shared/styles/commonStyle';
import {IColumn} from 'shared/types/tableTypes';

import {
    IAdditionalAgreementListItem,
    IAgreementAddition,
    IToggledAdditionalAgreementListItem,
} from 'shared/types/additionalAgreementsTypes';

interface AgreementTableContentProps {
    columns: IColumn<IToggledAdditionalAgreementListItem>[];
    innerColumns: IColumn<IAgreementAddition>[];
    openedIds: string[];
    toggleOpenDetails: (id: string) => void;
    agreements: IAdditionalAgreementListItem[];
}

export function AgreementTableContent({
    columns,
    innerColumns,
    openedIds,
    agreements,
    toggleOpenDetails,
}: AgreementTableContentProps) {
    const navigate = useNavigate();

    return (
        <>
            {agreements?.map((agreement) => (
                <React.Fragment key={agreement.id}>
                    <TableRow
                        onClick={() => navigate(`/additional-agreements/${agreement.id}`)}
                        hover
                        dense
                    >
                        {columns?.map((col, index) => (
                            <CustomTableData
                                key={`${col.name} ${agreement.id}`}
                                width={col.width}
                                height="52px"
                                onClick={(e) => {
                                    if (index !== 0) {
                                        return 1;
                                    }
                                    e.stopPropagation();
                                    toggleOpenDetails(agreement.id);
                                }}
                                dense
                            >
                                {col.render &&
                                    col.render({
                                        ...agreement,
                                        isOpen: openedIds.includes(agreement.id),
                                        toggleOpenDetails,
                                    })}
                            </CustomTableData>
                        ))}
                    </TableRow>

                    {openedIds.includes(agreement.id) &&
                        agreement.addendum?.map((additional) => (
                            <InnerTableRow key={additional.id}>
                                {innerColumns?.map((col, index) => (
                                    <CustomTableData
                                        key={`${additional.id} ${col.name} ${col.name} ${index}`}
                                        height="52px"
                                        dense
                                    >
                                        {col.render && col.render(additional)}
                                    </CustomTableData>
                                ))}
                            </InnerTableRow>
                        ))}
                </React.Fragment>
            ))}
        </>
    );
}
