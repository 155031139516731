import {differenceInCalendarDays, max} from 'date-fns';

import {
    ConstructorVirType,
    ConstructorVirWorkObject,
    EAdditionalAgreementType,
    IUpdatedAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {TpiSpecification} from 'pages/VirSelect/types';
import {formatDate} from 'shared/helpers/formatHelper';

import {GetSpec, GetVir, GetWorkObject} from './types';

export function getVirs({ds}: {ds: IUpdatedAgreement}): Record<string, ConstructorVirType> {
    return ds.works;
}

export function getVir({ds, virId}: GetVir): ConstructorVirType {
    return ds.works?.[virId];
}

export function getVirWorkObjects({ds, virId}: GetVir): Record<string, ConstructorVirWorkObject> {
    return ds.works?.[virId]?.work_objects || {};
}

export function getVirWorkObject({
    ds,
    virId,
    workObjectId,
}: GetWorkObject): ConstructorVirWorkObject {
    return ds.works[virId].work_objects?.[workObjectId];
}

export function getSpec({ds, virId, workObjectId, specId}: GetSpec): TpiSpecification {
    return ds.works?.[virId]?.work_objects?.[workObjectId]?.work_specifications?.[specId] || {};
}

export function getWorkObjectSpecs({
    ds,
    virId,
    workObjectId,
}: GetWorkObject): Record<string, TpiSpecification> {
    return ds?.works?.[virId]?.work_objects?.[workObjectId]?.work_specifications as Record<
        string,
        TpiSpecification
    >;
}

export function getVirWorkObjectSpecsArray({
    ds,
    virId,
    workObjectId,
}: GetWorkObject): TpiSpecification[] {
    return Object.values(
        ds.works[virId].work_objects?.[workObjectId]?.work_specifications as Record<
            string,
            TpiSpecification
        >,
    );
}

export function getAllDatesFromSpecs(works: Record<string, ConstructorVirType>) {
    const workValues = Object.values(works);
    const virsWithoutEmptyWorkObjects = workValues.filter((el) => {
        return !!Object.keys(el?.work_objects || {})?.length;
    });
    return virsWithoutEmptyWorkObjects
        .map((el) =>
            Object.values(el?.work_objects)
                .map((e) =>
                    Object.values(e.work_specifications).map((e) => [e.begin_date, e.end_date]),
                )
                .flat()
                .flat(),
        )
        .flat()
        .map((el) => {
            const result = el === undefined ? null : el;
            return new Date(result as string | number | Date);
        });
}

export function getAllAttachmentsFromSpecs(
    works: Record<string, ConstructorVirType>,
): Array<{file_id: string; file: File}> {
    const worksArr = Object.values(works);
    const workObjectsArr = worksArr.map((el) => Object.values(el.work_objects)).flat();
    const specs = workObjectsArr.map((el) => el.work_specifications);
    const specsArr = specs.map((el) => Object.values(el)).flat();
    const attachmentsWithData = specsArr.filter(
        (el) => Object.values(el?.work_specification_attachments || {})?.length,
    );
    return (attachmentsWithData?.map((el) => el?.work_specification_attachments)?.flat() ||
        []) as unknown as Array<{file_id: string; file: File}>;
}

export function getAllVirsSpecs(ds: IUpdatedAgreement): TpiSpecification[] {
    const works = ds.works;

    return Object.values(works)
        .filter((el) => !!el?.work_objects)
        .map((el) =>
            Object.values(el.work_objects)
                .map((e) => Object.values(e.work_specifications))
                .flat()
                .flat(),
        )
        .flat();
}

// метод проверяет ДС на присутствие незаполненных работ
export const checkAgreementHasEmptyWorkSpecifications = (agreement: IUpdatedAgreement): boolean => {
    return getArrayOfEmptySpecifications(agreement)
        .flat()
        .some((item) => item === true);
};

// метод создает массив в массивом, в котором 1 уровень - ведомости ДС, 2 - булево наличие пунктов в объектах
export const getArrayOfEmptySpecifications = (agreement: IUpdatedAgreement): boolean[][] => {
    return Object.values(agreement.works).map((item) => {
        const newItem = Object.values(item.work_objects ?? []).map(
            (workItem) => Object.values(workItem.work_specifications).length === 0,
        );
        return newItem.length === 0 ? [true] : newItem;
    });
};

// метод создает массив номеров ведомостей, в которых есть объекты без пунктов
export const getPayrollListWithEmptyWorkSpecifications = (
    agreement: IUpdatedAgreement,
): number[] => {
    return getArrayOfEmptySpecifications(agreement)
        .map((list, index) => {
            if (list.includes(true)) {
                return index + 1;
            } else {
                return -1;
            }
        })
        .filter((item) => item > 0);
};

// проверка валидности коэф-тов в пунктах всего объекта
export const checkAgreementHasIncreasingCoefficientsWithNoAttachmentsOrComments = (
    specificationList: TpiSpecification[],
): boolean => {
    for (let i = 0; i < specificationList.length; i++) {
        if (
            checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments(
                specificationList[i],
            ) ||
            checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments(specificationList[i])
        ) {
            return true;
        }
    }
    return false;
};

// проверка пункта спецификации, если есть повышающий коэф-т, обязательно наличие коммента и вложения
export const checkSpecificationHasIncreasingCoefficientsWithNoAttachmentsOrComments = (
    specification: TpiSpecification,
): boolean => {
    if (
        specification.work_coefficients?.some(
            (coefficient) => coefficient.type === 3 || coefficient.type === 4,
        )
    ) {
        if (
            (specification.work_specification_attachments?.length ?? 0) === 0 ||
            (specification.work_specification_comments?.length ?? 0) === 0
        ) {
            return true;
        }
    }

    return false;
};

// проверка пункта спецификации, если есть ручной коэф-т > 1, обязательно наличие коммента
export const checkSpecificationHasManualCoefficientsWithNoAttachmentsOrComments = (
    specification: TpiSpecification,
): boolean => {
    if (
        specification.work_coefficients?.some(
            (coefficient) => coefficient.is_manual && coefficient.coefficient > 1,
        )
    ) {
        if ((specification.work_specification_comments?.length ?? 0) === 0) {
            return true;
        }
    }
    return false;
};

export const checkInvalidEndDates = (
    list: TpiSpecification[],
    mode?: EAdditionalAgreementType,
    signingDate?: string | null,
) => {
    const endDateList = list.map((item) => item.end_date);
    let result: Date[] = [];
    endDateList.forEach((item) => {
        if (!!item) {
            result.push(new Date(item));
        }
    });

    switch (mode) {
        case EAdditionalAgreementType.Agreement: {
            const minDate = new Date();
            minDate.setDate(minDate.getDate() + 10);
            // любая дата меньше чем текущая + 10 дней
            if (result.some((item) => differenceInCalendarDays(item, minDate) < 0)) return true;
            // разница больше 3 лет
            return differenceInCalendarDays(max(result), Date.now()) > 1095;
        }
        case EAdditionalAgreementType.Addition: {
            if (!signingDate) return true;
            // если есть хоть одна дата окончания раньше даты подписания
            if (
                result.some((item) => {
                    return differenceInCalendarDays(item, new Date(signingDate)) < 0;
                })
            )
                return true;
            // на 5+ лет в будущем
            return differenceInCalendarDays(max(result), Date.now()) > 1825;
        }
        case EAdditionalAgreementType.Termination: {
            return false;
        }
    }
};

// формирование текстовой ошибки полей "окончание"
export const getInvalidEndDatesBannerTitle = (
    ds: IUpdatedAgreement,
    agreementType: EAdditionalAgreementType | null,
) => {
    let resultString = '';
    const specList: Date[] = [];
    getAllVirsSpecs(ds).forEach((item) => {
        if (item.end_date) {
            specList.push(new Date(item.end_date));
        }
    });

    const minDate = new Date();

    switch (agreementType) {
        case EAdditionalAgreementType.Agreement: {
            minDate.setDate(minDate.getDate() + 10);
            break;
        }
        case EAdditionalAgreementType.Addition: {
            minDate.setDate(new Date(ds.parent_signing_date ?? Date.now()).getDate());
            break;
        }
    }

    if (specList.some((item) => differenceInCalendarDays(item, minDate) < 0)) {
        resultString = `Даты окончания пунктов не должны быть раньше чем ${formatDate(minDate)}\n`;
    }

    if (differenceInCalendarDays(max(specList), Date.now()) > 1095) {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() + 3);
        resultString = `${resultString}Дата окончания пункта работ не может быть позже ${formatDate(
            maxDate,
        )}`;
    }
    return resultString;
};

//
export const getInvalidNotOpenedSheetsBannerTitle = (agreement: IUpdatedAgreement): string => {
    const emptyPayrollIndexList = getPayrollListWithEmptyWorkSpecifications(agreement);
    if (emptyPayrollIndexList.length === 0) return '';
    const isEmptyPayrollAlone = emptyPayrollIndexList.length === 1;
    const payrollString = `ведомост${isEmptyPayrollAlone ? 'ь' : 'и'}`;
    return `Добавьте работы в ${payrollString} ${emptyPayrollIndexList.join(
        ', ',
    )} или удалите пуст${isEmptyPayrollAlone ? 'ую' : 'ые'} ${payrollString}`;
};
