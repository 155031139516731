import styled, {css} from 'styled-components';

export const TableContent = styled.div<{isLoading: boolean}>`
    width: 100%;
    flex-shrink: 1;
    ${(props) =>
        !props.isLoading &&
        css`
            border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
            border-radius: var(--bs-border-radius);
        `}
`;

export const TableWrapper = styled.div`
    width: 100%;
`;

export const CustomTable = styled.div<{minWidth?: number | string}>``;

export const CustomTableRow = styled.div<{
    minWidth?: number | string;
    height?: string;
    justifyContent?: string;
    alignItems?: string;
    noBorder?: boolean;
    flex?: string;
}>`
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    transition-duration: 0.2s;
    ${(props) =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth};
        `}
    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
  ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
  ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}
  ${(props) =>
        props.noBorder &&
        css`
            border-bottom: none;
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
`;

export const CustomTableCell = styled.div<{
    justifyContent?: string;
    alignItems?: string;
    flex?: string;
    flexGrow?: number;
    flexBasis?: string;
    flexShrink?: string;
    noPadding?: boolean;
    scroll?: boolean;
    noFlex?: boolean;
    height?: string;
}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 52px;
    padding: 8px 16px;

    ${(props) =>
        props.justifyContent &&
        css`
            justify-content: ${props.justifyContent};
        `}
    ${(props) =>
        props.alignItems &&
        css`
            align-items: ${props.alignItems};
        `}
  ${(props) =>
        props.flex &&
        css`
            flex: ${props.flex};
        `}
  ${(props) =>
        props.noPadding &&
        css`
            padding: 0;
        `}
  ${(props) =>
        props.scroll &&
        css`
            overflow-x: auto;
        `}
  ${(props) =>
        props.noFlex &&
        css`
            display: block;
        `}
  ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
`;

export const RowWrapper = styled.div`
    width: 1336px;
    display: table;
`;
