import React from 'react';

import * as S from './style';

interface RoundedWrapperProps {
    children: React.ReactNode;
    width: string;
}

export const RoundedWrapper = ({children, width}: RoundedWrapperProps) => {
    return <S.RoundedCard width={width}>{children}</S.RoundedCard>;
};
