import React, {Dispatch, SetStateAction} from 'react';
import {Typography} from '@beeline/design-system-react';
import {TableCellContent} from 'shared/ui/Table/Table';
import {
    PurchasingEventStatusEnum,
    TPurchasingEventInfoForStage1,
} from 'shared/types/purchasesTypes';

import * as S from './style';
import * as D from '../style';
import {NonMvpItem, StyledSpan} from 'shared/styles/commonStyle';
import {formatDate} from 'shared/helpers/formatHelper';
import {SelectItem} from 'shared/ui/Select/Select';
import {TTpiWithRemoteTerritory} from 'shared/types/tpiTypes';

interface PrimeContractorsTableRowProps {
    data: TPurchasingEventInfoForStage1;
    selectedTpiData: Record<string, TTpiWithRemoteTerritory>;
    setSelectedTpiData: (props: Record<string, TTpiWithRemoteTerritory>) => void;
    disabled: boolean;
    setDisabled: Dispatch<SetStateAction<boolean>>;
    selectError: string | null;
}

export const PrimeContractorsTableRow = ({
    data,
    selectedTpiData,
    setSelectedTpiData,
    disabled,
    setDisabled,
    selectError,
}: PrimeContractorsTableRowProps) => {
    const {
        bidding_participation: {
            id,
            amount,
            percent,
            status,
            organization_short_name,
            organization_inn,
            purchase_lot: {name: purchase_lot_name},
            rank,
            is_screening,
            tpis,
            tpiList,
        },
        purchasing_event: {type, number},
        contract: {date_conclusion, number: ContractNumber, signatory},
    } = data;

    /*const totalAmount = +amount;
    const availableAmountPercentage = +percent;
    const availableAmount = totalAmount * (availableAmountPercentage / 100);
    const modelCost = model_cost ?? 0;
    const modelCostPercentage = ((modelCost / totalAmount) * 100).toFixed(0);
    const approvedSalary = 0;
    const approvedSalaryPercentage = '0';
    const prToBeAgreed = 0;
    const prToBeAgreedPercentage = '0';
    const percentsLabelColor =
        percent > 20 ? (availableAmount > (model_cost ?? 0) ? 'success' : 'warning') : 'error';*/

    const getStatusTitle = (): string => {
        switch (status) {
            case PurchasingEventStatusEnum.WIN:
                return 'Победитель';
            case PurchasingEventStatusEnum.PRE_WIN:
                return 'Условный победитель';
            case PurchasingEventStatusEnum.RES:
                return 'Выведен из резерва';
            case PurchasingEventStatusEnum.EXCL:
                return 'Исключен';
            default:
                return '';
        }
    };

    const handleSelectValue = (value) => {
        setSelectedTpiData({
            [id]: value,
        });
    };

    const hasSelectError = selectError && selectError === id;

    return (
        <S.RowWrapper>
            <D.CustomTableRow>
                <D.CustomTableCell flex="0 0 102px">
                    <Typography variant="body3">{type}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 100px">
                    <Typography variant="body3">{number}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 90px">
                    <Typography variant="body3">{purchase_lot_name}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 148px" alignItems="flex-end" noPadding>
                    {(tpiList?.length ?? -1) > 0 && (
                        <S.StyledSelect
                            value={selectedTpiData[id] ?? null}
                            valueKey="id"
                            valueLabel="name"
                            title="ТЦП"
                            handleEdit={handleSelectValue}
                            error={hasSelectError}
                            full
                            fullWidth
                        >
                            {tpiList?.map((item, index) => (
                                <SelectItem
                                    key={`${id}${item.id}${item.remote_territory_id}${index}`}
                                    value={item}
                                    selected={[]}
                                    multiple={false}
                                >
                                    {item.name}
                                </SelectItem>
                            ))}
                        </S.StyledSelect>
                    )}
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 230px">
                    <TableCellContent>
                        <S.StyledSpan>
                            <Typography variant="body3">{organization_short_name}</Typography>
                            <Typography className="inactiveText" variant="body3">
                                {organization_inn}
                            </Typography>
                        </S.StyledSpan>
                    </TableCellContent>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 114px">
                    <Typography variant="body3">{getStatusTitle()}</Typography>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 202px">
                    <S.StyledSpan>
                        <Typography variant="body3">
                            {ContractNumber} от {formatDate(date_conclusion)}
                        </Typography>
                    </S.StyledSpan>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 202px">
                    <StyledSpan lineCount={1}>
                        <Typography variant="body3">{signatory}</Typography>
                    </StyledSpan>
                </D.CustomTableCell>
                <D.CustomTableCell flex="0 0 148px" alignItems="flex-end">
                    <NonMvpItem>
                        {/*<TableCellContent right>{availableAmount.toLocaleString()}</TableCellContent>*/}
                    </NonMvpItem>
                    <Typography variant="body3">{amount.toLocaleString()}</Typography>
                </D.CustomTableCell>

                {/*<TableCell>*/}
                {/*    <NonMvpItem>*/}
                {/*        <TableCellContent>*/}
                {/*            <Popover*/}
                {/*                content={*/}
                {/*                    <AvailableFundsPopoverContent*/}
                {/*                        availableAmount={availableAmount}*/}
                {/*                        availableAmountPercentage={availableAmountPercentage.toFixed(0)}*/}
                {/*                        modelCost={modelCost}*/}
                {/*                        modelCostPercentage={modelCostPercentage}*/}
                {/*                        approvedSalary={approvedSalary}*/}
                {/*                        approvedSalaryPercentage={approvedSalaryPercentage}*/}
                {/*                        prToBeAgreed={prToBeAgreed}*/}
                {/*                        prToBeAgreedPercentage={prToBeAgreedPercentage}*/}
                {/*                    />*/}
                {/*                }*/}
                {/*            >*/}
                {/*                <Label*/}
                {/*                    type={percentsLabelColor}*/}
                {/*                    variant="contained"*/}
                {/*                    title={`${(+percent).toFixed(0)}%`}*/}
                {/*                />*/}
                {/*            </Popover>*/}
                {/*        </TableCellContent>*/}
                {/*    </NonMvpItem>*/}
                {/*</TableCell>*/}
            </D.CustomTableRow>
        </S.RowWrapper>
    );
};
