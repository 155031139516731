import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';

import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {fetchPoData, postPoData} from 'shared/services/completedWorks.service';
import {forwardPayload, resetDomainStoresByEvents} from 'shared/helpers/effector';

import {subpageWorkflowDone} from './index';
import {ECompletedWorksView, PoNumberCreationProps} from './helpers';
import {ETaskTypeConfirmations} from '../../DSPage/types';

const PoNumberCreationDomain = createDomain();

export const PoNumberCreationGate = createGate<PoNumberCreationProps>();

// effects
const fetchPoDataFx = PoNumberCreationDomain.effect(async (id: string) => {
    try {
        const result = await fetchPoData(id);
        return result.data.number_po;
    } catch (e) {
        console.warn(e);
        return null;
    }
});

const sendPoDataFx = PoNumberCreationDomain.effect(
    async ({id, poNumber, buttonIndex}: {id: string; poNumber: string; buttonIndex: number}) => {
        try {
            const result = await postPoData(id, poNumber);
            return result.status === 200 ? buttonIndex : -1;
        } catch (e) {
            snackbarNotificationChanged(e);
            return -1;
        }
    },
);
// events
export const poNumberChanged = PoNumberCreationDomain.event<string>();
export const positiveButtonClicked = PoNumberCreationDomain.event<number>();
const workflowCompleted = PoNumberCreationDomain.event<number>();

// stores
const $poNumber = PoNumberCreationDomain.store<string>('');
const $formTouched = PoNumberCreationDomain.store(false);
const $shouldCloseSubpage = PoNumberCreationDomain.store(false);

export const $poDocumentsStore = combine({
    poNumber: $poNumber,
    poNumberError: $poNumber.map((number) => number.length === 0),
    formTouched: $formTouched,
    shouldCloseSubpage: $shouldCloseSubpage,
    isLoading: fetchPoDataFx.pending,
    buttonDisabled: sendPoDataFx.pending,
});

sample({
    clock: PoNumberCreationGate.open,
    fn: (gate) => gate.id,
    target: fetchPoDataFx,
});

sample({
    clock: fetchPoDataFx.doneData,
    source: PoNumberCreationGate.state,
    filter: (gate, data) => gate.mode === ECompletedWorksView.mandatoryPo || !data,
    fn: (_, data) => data ?? '',
    target: $poNumber,
});

sample({
    clock: fetchPoDataFx.doneData,
    source: PoNumberCreationGate.state,
    filter: (gate, data) => gate.mode === ECompletedWorksView.po && !!data,
    fn: ({taskData, mode}, _) =>
        taskData[0]?.actions.findIndex(
            (item) =>
                item.is_positive === null &&
                item.type_confirmation ===
                    (mode === ECompletedWorksView.po
                        ? ETaskTypeConfirmations.PO
                        : ETaskTypeConfirmations.MandatoryPO),
        ) ?? -1,
    target: workflowCompleted,
});

sample({
    clock: positiveButtonClicked,
    fn: () => true,
    target: $formTouched,
});

// нажатие на воркфлоу кнопку
sample({
    clock: positiveButtonClicked,
    source: combine({
        gate: PoNumberCreationGate.state,
        data: $poNumber,
    }),
    filter: ({data}, _) => !!data,
    fn: ({gate, data}, buttonIndex) => ({id: gate.id, poNumber: data ?? '', buttonIndex}),
    target: sendPoDataFx,
});

// Успешное завершение воркфлоу страницы
sample({
    clock: sendPoDataFx.doneData,
    filter: (index) => index !== -1,
    target: workflowCompleted,
});

sample({
    clock: workflowCompleted,
    fn: () => 'Номер ЗП создан',
    target: snackbarNotificationChanged,
});

sample({
    clock: workflowCompleted,
    fn: () => true,
    target: $shouldCloseSubpage,
});

// передача индекса нажатой кнопки
sample({
    clock: workflowCompleted,
    target: subpageWorkflowDone,
});

$poNumber.on(poNumberChanged, forwardPayload());

resetDomainStoresByEvents(PoNumberCreationDomain, PoNumberCreationGate.close);
