import React from 'react';
import {Label} from '@beeline/design-system-react';
import {IColumn} from 'shared/types/tableTypes';
import {AllStatuses} from '@beeline/design-system-react/types/types/status';

import {TJobListItem} from 'shared/types/jobsTypes';
import {labelVariant} from 'shared/helpers/projectsColumnSettings/projectsColumnSettings';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {TooltipedSpan} from 'shared/ui/TooltipedSpan';

export const jobListColumns: IColumn<TJobListItem>[] = [
    {
        name: 'jobNumber',
        label: 'Задание',
        hasSorting: false,
        width: '90px',
        render: ({job_tasks_number}) => job_tasks_number,
    },
    {
        name: 'contractor',
        label: 'ГПО',
        hasSorting: false,
        render: ({contractor_short_name}) => (
            <TooltipedSpan title={contractor_short_name} lineCount={2}>
                {contractor_short_name}
            </TooltipedSpan>
        ),
    },
    {
        name: 'purchasingEventNumber',
        label: '№ ЗМ',
        hasSorting: false,
        width: '120px',
        render: ({purchasing_event}) => purchasing_event.number,
    },
    {
        name: 'lot',
        label: 'Лот',
        hasSorting: false,
        width: '120px',
        render: ({purchase_lot}) => purchase_lot.name,
    },
    {
        name: 'contract',
        label: 'Договор',
        hasSorting: false,
        render: ({contract}) => (
            <TooltipedSpan title={contract.number} lineCount={2}>
                {contract.number}
            </TooltipedSpan>
        ),
    },
    {
        name: 'creationDate',
        label: 'Дата выдачи',
        hasSorting: false,
        width: '150px',
        render: ({created_at}) => formatDateHours(created_at),
    },
    {
        name: 'answerDate',
        label: 'Дата ответа',
        hasSorting: false,
        width: '150px',
        render: ({approved_at}) => formatDateHours(approved_at),
    },
    {
        name: 'status',
        label: 'Статус',
        hasSorting: false,
        render: ({status}) => (
            <Label title={status.name} type={labelVariant(status.token) as AllStatuses} />
        ),
    },
];
