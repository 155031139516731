import {createGate} from 'effector-react';
import {combine, createDomain, forward, sample} from 'effector';

import {
    getAdditionalAgreementCopy,
    getAdditionalAgreementListToCopy,
} from 'shared/services/ds.service';
import {
    AdditionalAgreementsParams,
    IAdditionalAgreementListItem,
    TAdditionalAgreementCopy,
    TCopyingAdditionalAgreement,
} from 'shared/types/additionalAgreementsTypes';
import {forwardPayload} from 'shared/helpers/effector';

import {additionalAgreementsService} from '../service/agreements.service';

export const additionalAgreementsDomain = createDomain();

export const AdditionalAgreementsGate = createGate<{projectId: string}>();

// effects
export const loadAdditionalAgreementsFx = additionalAgreementsDomain.createEffect(
    additionalAgreementsService.getAllAdditionalAgreements,
);

export const getAddAgreementAdditionFx = additionalAgreementsDomain.createEffect(
    async (id: string) => {
        try {
            const result = await getAdditionalAgreementCopy(id);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

export const getCopyingAddAgreementListFx = additionalAgreementsDomain.createEffect(
    async (id: string) => {
        try {
            const result = await getAdditionalAgreementListToCopy(id);
            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },
);

//events
export const toggleOpenDetails = additionalAgreementsDomain.createEvent<string>();
export const setPage = additionalAgreementsDomain.createEvent<number>();
export const setPageSize = additionalAgreementsDomain.createEvent<number>();
export const createAddAgreementAddition = additionalAgreementsDomain.createEvent<string>();
export const resetAddAgreementCopy = additionalAgreementsDomain.createEvent();

// stores
export const $projectId = additionalAgreementsDomain.createStore<string>('');
export const $agreements = additionalAgreementsDomain.createStore<
    Array<IAdditionalAgreementListItem>
>([]);
export const $openedDetailedIds = additionalAgreementsDomain.createStore<string[]>([]);
const $tableParams = additionalAgreementsDomain.createStore<
    Required<Pick<AdditionalAgreementsParams, 'project_id' | 'page' | 'page_size'>> & {
        total: number;
    }
>({
    page: 1,
    page_size: 10,
    project_id: '',
    total: 0,
});
export const $page = additionalAgreementsDomain.createStore<number>(1);
export const $pageSize = additionalAgreementsDomain.createStore<number>(10);
export const $copyingAddAgreementList = additionalAgreementsDomain
    .createStore<TCopyingAdditionalAgreement[]>([])
    .on(getCopyingAddAgreementListFx.doneData, forwardPayload());
export const $createdAddAgreementCopy = additionalAgreementsDomain
    .createStore<TAdditionalAgreementCopy | null>(null)
    .reset(resetAddAgreementCopy);

export const $additionalAgreementsTable = combine({
    isLoading: loadAdditionalAgreementsFx.pending,
    agreements: $agreements,
    openedIds: $openedDetailedIds,
    copyingAgreementList: $copyingAddAgreementList,
    agreementCopy: $createdAddAgreementCopy,
    isLoadingAddAgreementCopy: getCopyingAddAgreementListFx.pending,
    tableParams: $tableParams,
});

sample({
    clock: AdditionalAgreementsGate.open,
    source: $tableParams,
    fn: (source, gate) => ({...source, project_id: gate.projectId}),
    target: $tableParams,
});

sample({
    clock: AdditionalAgreementsGate.open,
    filter: (gate) => !!gate.projectId,
    fn: (gate) => gate.projectId,
    target: $projectId,
});

$agreements.on(loadAdditionalAgreementsFx.doneData, (state, {data: {results}}) => results);
$tableParams.on(loadAdditionalAgreementsFx.doneData, (state, {data: {count}}) => {
    return state.total === count
        ? undefined
        : {
              ...state,
              total: count,
          };
});

sample({
    source: $tableParams,
    filter: (source) => !!source.project_id,
    target: loadAdditionalAgreementsFx,
});

sample({
    source: $projectId,
    filter: (source) => !!source,
    target: getCopyingAddAgreementListFx,
});

$tableParams.on(setPage, (state, payload) => ({...state, page: payload}));
$tableParams.on(setPageSize, (state, payload) => ({...state, page: 1, page_size: payload}));

$openedDetailedIds.on(toggleOpenDetails, (state, payload) => {
    if (state.includes(payload)) {
        return state.filter((el) => el !== payload);
    }
    return [...state, payload];
});

forward({
    from: createAddAgreementAddition,
    to: getAddAgreementAdditionFx,
});

$createdAddAgreementCopy.on(getAddAgreementAdditionFx.doneData, (state, payload) => {
    return payload;
});

forward({
    from: AdditionalAgreementsGate.close,
    to: resetAddAgreementCopy,
});
