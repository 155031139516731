import {combine, createDomain, sample} from 'effector';
import {createGate} from 'effector-react';
import {isAfter} from 'date-fns';

import {or} from 'patronum';
import {snackbarNotificationChanged} from 'Entities/SnackbarNotification/model';
import {forwardPayload} from 'shared/helpers/effector';
import {
    fetchClosingDocumentData,
    postClosingDocumentData,
} from 'shared/services/completedWorks.service';
import {TClosingDocumentInfo} from 'shared/types/completedWorksTypes';

import {subpageWorkflowDone} from './index';
import {
    mapInputClosingDocuments,
    mapOutputClosingDocuments,
    TOutputClosingDocuments,
} from './helpers';

const ClosingDocumentCreationDomain = createDomain();
export const ClosingDocumentCreationGate = createGate<string | undefined>();

// effects
const getDataFx = ClosingDocumentCreationDomain.effect(async (id: string) => {
    try {
        const result = await fetchClosingDocumentData(id);
        return result.data.xml_docs;
    } catch (e) {
        console.warn(e);
    }
});

const postDataFx = ClosingDocumentCreationDomain.effect(
    async ({id, index, data}: TOutputClosingDocuments) => {
        try {
            const result = await postClosingDocumentData(id, data);
            snackbarNotificationChanged(result?.data?.message ?? 'Не удалось сохранить значения');
            return result.status === 200 ? index : false;
        } catch (e) {
            console.warn(e);
            return false;
        }
    },
);
// events
export const ks2DateChanged = ClosingDocumentCreationDomain.event<string>();
export const ks2DocNumberChanged = ClosingDocumentCreationDomain.event<string>();
export const ks3DocNumberChanged = ClosingDocumentCreationDomain.event<string>();
export const invoiceDateChanged = ClosingDocumentCreationDomain.event<string>();
export const invoiceDocNumberChanged = ClosingDocumentCreationDomain.event<string>();
export const positiveButtonClicked = ClosingDocumentCreationDomain.event<number>();
export const dataChecked = ClosingDocumentCreationDomain.event();
// stores

const $closingDocumentData = ClosingDocumentCreationDomain.store<TClosingDocumentInfo[]>([]);
const $ks2Date = ClosingDocumentCreationDomain.store('');
const $ks2DocNumber = ClosingDocumentCreationDomain.store('');
const $ks3Date = ClosingDocumentCreationDomain.store('');
const $ks3DocNumber = ClosingDocumentCreationDomain.store('');
const $invoiceDate = ClosingDocumentCreationDomain.store('');
const $invoiceDocNumber = ClosingDocumentCreationDomain.store('');
const $formTouched = ClosingDocumentCreationDomain.store(false);
const $shouldCloseWidget = ClosingDocumentCreationDomain.store(false);
const $invoiceDataShowed = ClosingDocumentCreationDomain.store(false);

export const $errorStore = ClosingDocumentCreationDomain.store<{
    ks2DateError: boolean;
    ks2DocNumberError: boolean;
    ks3DateError: boolean;
    ks3DocNumberError: boolean;
    invoiceDateError: boolean;
    invoiceDocNumberError: boolean;
}>({
    ks2DateError: false,
    invoiceDateError: false,
    ks3DateError: false,
    ks2DocNumberError: false,
    invoiceDocNumberError: false,
    ks3DocNumberError: false,
});

export const $closingDocumentsCreationStore = combine({
    ks2Date: $ks2Date,
    ks2DocNumber: $ks2DocNumber,
    ks3Date: $ks3Date,
    ks3DocNumber: $ks3DocNumber,
    invoiceDate: $invoiceDate,
    invoiceDocNumber: $invoiceDocNumber,
    formTouched: $formTouched,
    isLoading: or(getDataFx.pending, postDataFx.pending),
    initialLoading: getDataFx.pending,
    shouldCloseWidget: $shouldCloseWidget,
    invoiceDataShowed: $invoiceDataShowed,
});

// запрос данных при открытии виджета
sample({
    clock: ClosingDocumentCreationGate.open,
    filter: (id) => !!id,
    fn: (id) => id ?? '',
    target: getDataFx,
});

// гидрация данных с бэка
sample({
    clock: getDataFx.doneData,
    fn: (data) => mapInputClosingDocuments(data),
    target: $closingDocumentData,
});

// определяем, показывать данный по документу "счет фактура" или нет
sample({
    clock: getDataFx.doneData,
    fn: (data) => !!data?.find((item) => item.doc_type === 'sf'),
    target: $invoiceDataShowed,
});

sample({
    clock: positiveButtonClicked,
    target: dataChecked,
});

sample({
    clock: positiveButtonClicked,
    source: combine({
        errorStore: $errorStore,
        dataStore: $closingDocumentsCreationStore,
        id: ClosingDocumentCreationGate.state,
        invoiceDataShowed: $invoiceDataShowed,
    }),
    filter: ({errorStore}) => {
        for (let key in errorStore) {
            if (errorStore[key] === true) return false;
        }
        return true;
    },
    fn: ({dataStore, id, invoiceDataShowed}, index) =>
        mapOutputClosingDocuments(id, index, dataStore, invoiceDataShowed),
    target: postDataFx,
});

// передача номера кнопки в случае успешного пост запроса
sample({
    clock: postDataFx.doneData,
    filter: (result) => typeof result === 'number',
    fn: (result) => (typeof result === 'number' ? result : 0),
    target: subpageWorkflowDone,
});

sample({
    clock: postDataFx.doneData,
    fn: (result) => typeof result === 'number',
    target: $shouldCloseWidget,
});

$ks2Date
    .on(ks2DateChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'ks_2')?.doc_date,
    );
$ks2DocNumber
    .on(ks2DocNumberChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'ks_2')?.doc_number,
    );
$ks3Date
    .on(ks2DateChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'ks_3')?.doc_date,
    );
$ks3DocNumber
    .on(ks3DocNumberChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'ks_3')?.doc_number,
    );
$invoiceDate
    .on(invoiceDateChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'sf')?.doc_date,
    );
$invoiceDocNumber
    .on(invoiceDocNumberChanged, forwardPayload())
    .on(
        $closingDocumentData,
        (_, payload) => payload.find((item) => item.doc_type === 'sf')?.doc_number,
    );

sample({
    clock: dataChecked,
    fn: () => true,
    target: $formTouched,
});

sample({
    source: combine({
        store: $closingDocumentsCreationStore,
        invoiceDataShowed: $invoiceDataShowed,
    }),
    fn: ({store, invoiceDataShowed}) => ({
        ks2DateError: !store.ks2Date || isAfter(new Date(store.ks2Date), Date.now()),
        ks2DocNumberError: !store.ks2DocNumber,
        ks3DateError: !store.ks3Date || isAfter(new Date(store.ks3Date), Date.now()),
        ks3DocNumberError: !store.ks3DocNumber,
        invoiceDateError: invoiceDataShowed ? !store.invoiceDate : false,
        invoiceDocNumberError: invoiceDataShowed ? !store.invoiceDocNumber : false,
    }),
    target: $errorStore,
});
