import React from 'react';
import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';

import Select, {SelectItem} from 'shared/ui/Select/Select';
import {
    $createVirStartPageData,
    $createVirStartPageOptions,
    selectWorkObject,
} from 'pages/VirStart/model';
import {EWorkObjectTypes} from 'shared/types/additionalAgreementsTypes';

import * as S from './styled';

export function WorkObjectSelect() {
    const {objectOptions} = useStore($createVirStartPageOptions);
    const {selectedObject} = useStore($createVirStartPageData);
    const {loadingWorkObjects} = useStore($createVirStartPageOptions);
    const {formErrors} = useStore($createVirStartPageOptions);
    return (
        <S.SelectWrapper className="col-6">
            <Select
                value={selectedObject || []}
                valueLabel="label"
                handleEdit={selectWorkObject}
                title="Объект"
                disabled={loadingWorkObjects}
                error={formErrors?.object}
                hint={formErrors?.object ? 'Укажите объект' : ''}
                id="AgreementConstructorObjectSelect"
            >
                {[
                    ...objectOptions.map((item) => {
                        return (
                            <SelectItem
                                key={item.id}
                                nested={!!item?.nested}
                                multiple={false}
                                value={item}
                                selected={selectedObject}
                                id={item.label}
                                disabled={!item.object_type}
                            >
                                <S.MainText>
                                    {item?.label}
                                    {!item?.object_type && (
                                        <S.SubText active={false}>
                                            Пролёт (полный комплект)
                                        </S.SubText>
                                    )}
                                </S.MainText>

                                <S.SubText active>
                                    {item.object_type === EWorkObjectTypes.MAIN_PART &&
                                        'Основная часть (полукомплект)'}
                                    {item.object_type === EWorkObjectTypes.RESPONSE_PART &&
                                        'Ответная часть (полукомплект)'}
                                </S.SubText>
                            </SelectItem>
                        );
                    }),
                    <S.TipText variant="body2" key="notFound">
                        Нет нужного объекта? Напишите на{' '}
                        <Link to={'mailto:dmc@beeline.ru'}>dmc@beeline.ru</Link>
                    </S.TipText>,
                ]}
            </Select>
        </S.SelectWrapper>
    );
}
