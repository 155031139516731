import {
    ICompletedWorksAgreement,
    ICompletedWorksDocument,
    ICompletedWorksListItem,
    ICompletedWorksListResponse,
    TBackendCompletedWorksAgreement,
    TClosingDocumentInfo,
} from 'shared/types/completedWorksTypes';
import {IPaginationResponse} from 'shared/types/apiTypes';
import {axiosInstance} from 'shared/api/api';

import {TClosingDocumentResponse, TPoDetailResponse} from './types/completedWorksTypes';

export const fetchCompletedWorksList = (projectId: string) => {
    return axiosInstance.get<IPaginationResponse<ICompletedWorksListItem>>(`vvr/vvr/`, {
        params: {project_id: projectId},
    });
};

export const fetchNewCompletedWorksList = (id: string, page: number, pageSize: number) => {
    return axiosInstance.get<IPaginationResponse<ICompletedWorksListResponse>>(`vvr/vvr/ds/`, {
        params: {project_id: id, page: page, page_size: pageSize},
    });
};

export const postCompletedWorksAgreement = (agreement: TBackendCompletedWorksAgreement) => {
    return axiosInstance.post<{id: string}>('vvr/vvr/', agreement);
};

export const putCompletedWorksAgreement = (
    agreement: TBackendCompletedWorksAgreement,
    id: string,
) => {
    return axiosInstance.put<{id: string}>(`vvr/vvr/${id}/`, agreement);
};

export const fetchCompletedWorksSheet = (id: string) => {
    return axiosInstance.get<ICompletedWorksDocument>(`vvr/vvr/${id}/`);
};

export const postApproveCompletedWorksSpecificationItem = (data: {
    id: string;
    action: boolean | null;
}) => {
    const {id, action} = data;

    return axiosInstance.post(`vvr/completed_work_specifications/${id}/approve`, {
        approved: action,
    });
};
export const fetchAgreementListForCompletedWorksCreation = (projectId: string) => {
    return axiosInstance.get<ICompletedWorksAgreement[]>(`vvr/ds_available/`, {
        params: {
            project_id: projectId,
        },
    });
};
export const fetchAgreementForCompletedWorksCreation = (agreementId: string) => {
    return axiosInstance.get<ICompletedWorksAgreement>(`vvr/ds_available/${agreementId}/get_ds/`);
};

export const postCompletedWorksComment = (id: string, comment: string) => {
    return axiosInstance.post<ICompletedWorksDocument>(`vvr/vvr/${id}/add_comment/`, {
        comment: comment,
    });
};

export const fetchCompletedWorksFile = (id: string) => {
    return axiosInstance.get(`/vvr/vvr/${id}/vvr_file/`, {
        responseType: 'blob',
        timeout: 200000,
    });
};

export const putCompletedWorksUpdate = (id: string) => {
    return axiosInstance.put<ICompletedWorksDocument>(`vvr/vvr/${id}/`, {meta: 'update_agreement'});
};

export const fetchClosingDocumentData = (id: string) => {
    return axiosInstance.get<TClosingDocumentResponse>(`vvr/vvr/${id}/xml_data/`);
};

export const postClosingDocumentData = (id: string, data: TClosingDocumentInfo[]) => {
    return axiosInstance.post<{message: string}>(`vvr/vvr/${id}/xml_data/`, data);
};

export const fetchPoData = (id: string) => {
    return axiosInstance.get<TPoDetailResponse>(`vvr/vvr/${id}/po_detail/`);
};

export const postPoData = (id: string, poNumber: string) => {
    return axiosInstance.post(`vvr/vvr/${id}/po_detail/`, {po_number: poNumber});
};
