import React from 'react';
import {Link} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {InformationalUnit} from 'Entities/InformationalUnit';
import {$userActions} from 'shared/model/user';
import {createLink} from 'shared/helpers/links';
import {formatDateHours} from 'shared/helpers/formatHelper';
import {userHasPermission} from 'shared/helpers/accessCheckers';
import {ICompletedWorksDocument} from 'shared/types/completedWorksTypes';
import {
    ADDITIONAL_AGREEMENT_FULL_TITLE,
    ADDRESS_TITLE,
    CONFORMING_DATE,
    CONTRACTOR_SHORT_TITLE,
    CREATION_DATE,
    LOT_TITLE,
    PROCUREMENT_EVENT_TITLE,
    WORK_TYPE_TITLE,
} from 'shared/constants';
import {EAccessActions} from 'shared/const/actions';

import * as S from './style';

type PageInfoUnitProps = Pick<
    ICompletedWorksDocument,
    | 'project'
    | 'created_at'
    | 'purchasing_event'
    | 'contract_name'
    | 'contract_id'
    | 'agreement'
    | 'approved_at'
    | 'gpo_short_name'
    | 'purchase_lots_name'
>;

export const PageInfoUnit = ({
    agreement,
    purchase_lots_name,
    project,
    approved_at,
    created_at,
    purchasing_event,
    contract_id,
    contract_name,
    gpo_short_name,
}: PageInfoUnitProps) => {
    const userActions = useUnit($userActions);
    const contractLinkAllowed = userHasPermission(
        EAccessActions.ContractListLinkShowing,
        userActions,
    );
    const {bs_name, bs_number, bs_address, project_type} = project;
    const {number: purchasingNumber} = purchasing_event;
    const {id, agreement_number} = agreement;
    return (
        <div>
            <Typography variant="h5">{`БС ${bs_number} — ${bs_name}`}</Typography>
            <S.GridWrapper>
                <InformationalUnit title={ADDRESS_TITLE} gridArea="address">
                    {bs_address}
                </InformationalUnit>
                <InformationalUnit title={CREATION_DATE} gridArea="creationDate">
                    {formatDateHours(created_at)}
                </InformationalUnit>
                <InformationalUnit title={CONFORMING_DATE} gridArea="approvingDate">
                    {formatDateHours(approved_at)}
                </InformationalUnit>
                <InformationalUnit title={PROCUREMENT_EVENT_TITLE} gridArea="purchase">
                    <>
                        <Typography variant="body2">{purchasingNumber}</Typography>
                        <Typography variant="body2">
                            {contractLinkAllowed ? (
                                <Link to={`/contracts/${contract_id}`}>{contract_name}</Link>
                            ) : (
                                contract_name
                            )}
                        </Typography>
                    </>
                </InformationalUnit>
                <InformationalUnit title={WORK_TYPE_TITLE} gridArea="jobType">
                    {project_type}
                </InformationalUnit>
                <InformationalUnit title={CONTRACTOR_SHORT_TITLE} gridArea="contractor">
                    {gpo_short_name}
                </InformationalUnit>
                <InformationalUnit title={ADDITIONAL_AGREEMENT_FULL_TITLE} gridArea="agreement">
                    <Link to={createLink(2, id)} target="_blank">
                        {agreement_number}
                    </Link>
                </InformationalUnit>
                <InformationalUnit title={LOT_TITLE}>{purchase_lots_name ?? '—'}</InformationalUnit>
            </S.GridWrapper>
        </div>
    );
};
