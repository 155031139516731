import styled from 'styled-components/macro';

export const VIRDataStyled = styled.div`
    padding-top: 20px;
    padding-bottom: 48px;
    --selectWidth: 496px;
`;

export const Wrapper = styled.div`
    margin-bottom: 36px;
`;
export const StyledProgressCircularWrap = styled.div`
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
