import React from 'react';
import {useGate, useUnit} from 'effector-react';
import {Button, Progress, TextField} from '@beeline/design-system-react';

import {TitleWithBackButton} from 'Features/TitleWithBackButton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {Wrapper} from 'shared/styles/commonStyle';

import {CANCEL_BUTTON_TITLE} from 'shared/constants';

import {
    $poDocumentsStore,
    poNumberChanged,
    PoNumberCreationGate,
    positiveButtonClicked,
} from '../model/poDocuments';
import {ECompletedWorksView, PoNumberCreationProps} from '../model/helpers';
import {ETaskTypeConfirmations} from '../../DSPage/types';

export const PoNumberCreation = (props: PoNumberCreationProps) => {
    useGate(PoNumberCreationGate, props);
    const {poNumber, poNumberError, formTouched, shouldCloseSubpage, isLoading, buttonDisabled} =
        useUnit($poDocumentsStore);
    const {taskData, handleClose, mode} = props;
    const workflowData = taskData[0];
    const signButtonData = workflowData.actions.find(
        (item) =>
            item.is_positive === null &&
            item.type_confirmation ===
                (mode === ECompletedWorksView.po
                    ? ETaskTypeConfirmations.PO
                    : ETaskTypeConfirmations.MandatoryPO),
    );
    const signButtonIndex = workflowData.actions.findIndex(
        (item) =>
            item.is_positive === null &&
            item.type_confirmation ===
                (mode === ECompletedWorksView.po
                    ? ETaskTypeConfirmations.PO
                    : ETaskTypeConfirmations.MandatoryPO),
    );
    if (shouldCloseSubpage) {
        handleClose();
    }

    return (
        <Wrapper>
            <TitleWithBackButton title="Заполнить номер ЗП" onClick={handleClose ?? (() => 1)} />
            {isLoading ? (
                <WrapperFlex height="300px" justifyContent="center" alignItems="center">
                    <Progress shape="circle" cycled />
                </WrapperFlex>
            ) : (
                <WrapperFlex flexDirection="column" gap="300px">
                    <TextField
                        label="Номер ЗП"
                        value={poNumber}
                        onChange={(e) => poNumberChanged(e.currentTarget.value)}
                        error={poNumberError && formTouched}
                    />
                    <WrapperFlex gap="16px" alignSelf="flex-end">
                        <Button variant="outlined" size="medium" onClick={handleClose}>
                            {CANCEL_BUTTON_TITLE}
                        </Button>
                        {!!signButtonData && (
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={() => positiveButtonClicked(signButtonIndex)}
                                disabled={
                                    buttonDisabled || isLoading || (poNumberError && formTouched)
                                }
                            >
                                {signButtonData.name}
                            </Button>
                        )}
                    </WrapperFlex>
                </WrapperFlex>
            )}
        </Wrapper>
    );
};
