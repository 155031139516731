import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {Progress, Typography} from '@beeline/design-system-react';

import {ViewProjectMainInfo} from 'Entities/ProjectMainInfo';
import {ViewPageTitle, ViewProjectTitleSkeleton} from 'Entities/ViewProjectTitle';
import {
    VerticalTabBtn,
    BtnTitle,
    BtnSubtitle,
} from 'pages/ProjectViewPage/ui/ProjectViewVerticalTabs/ProjectViewVerticalTabs';
import {CommonLayout} from 'shared/ui/CommonLayout'
import {TabsBtn, TabsBlock} from 'shared/ui/deprecated/Tabs/Tabs';
import WrapperOffset from 'shared/ui/Wrappers/WrapperOffset';
import {NonMvpItem} from 'shared/styles/commonStyle';
import {
    $stageTabItem,
    $viewProjectStore,
    setProjectViewTab,
    setStageTabItem,
    viewProjectGate,
} from 'pages/ProjectViewPage/model';

import {DocumentTable} from 'pages/ProjectViewPage/ui/DocumentTable';
import {$userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';
import {userHasPermission} from 'shared/helpers/accessCheckers';

import * as S from './style';
import {AdditionalAgreementsTab} from './AdditionalAgreementsTab';
import {CompletedWorksTab} from './CompletedWorksTab';
import {JobTab} from './JobTab';
import {$jobListCount} from '../model/jobTab';
import {COMPLETED_WORKS_TAB_TITLE} from '../constants';

export const ProjectViewPage = function () {
    const {projectId} = useParams<{projectId: string}>();
    const navigate = useNavigate();
    useGate(viewProjectGate, projectId);

    const stageTabItem = useStore($stageTabItem);
    const {projectData, isLoading, currentTab, projectIdFromNri} = useStore($viewProjectStore);
    const jobCount = useStore($jobListCount);
    const userActions = useStore($userActions);

    if (projectId && projectIdFromNri && projectIdFromNri !== projectId) {
        navigate(`/projects/${projectIdFromNri}`);
    }
    const documentListVisible = userActions.includes(EAccessActions.DocumentsList);
    const CompletedWorksTabVisible = userHasPermission(
        EAccessActions.CompletedWorksList,
        userActions,
    );

    const isNriId = Number.isInteger(+(projectId ?? ''));

    if (isNriId) {
        return (
            <S.WindowWrapper>
                <Progress
                    cycled
                    shape="circle"
                    size="standart"
                    strokeWidth={4}
                    type="solo"
                    value={75}
                />
            </S.WindowWrapper>
        );
    }
    return (
        <CommonLayout>
            <WrapperOffset offset="32">
                {isLoading ? (
                    <ViewProjectTitleSkeleton />
                ) : (
                    projectData && <ViewPageTitle data={projectData} />
                )}

                <ViewProjectMainInfo data={projectData} isLoading={isLoading} />

                <WrapperOffset>
                    <Typography variant="h5" className="mb-2 mt-5">
                        Ход работ
                    </Typography>
                    <S.BtnBlock>
                        <TabsBtn
                            id="stagesTab"
                            title="Этапы"
                            value={1}
                            activeTab={currentTab}
                            onClick={setProjectViewTab}
                        />
                        {documentListVisible && (
                            <TabsBtn
                                id="documentsTab"
                                title="Документы"
                                value={2}
                                activeTab={currentTab}
                                onClick={setProjectViewTab}
                            />
                        )}
                        <NonMvpItem>
                            <TabsBtn
                                title="Фотоотчеты"
                                value={3}
                                activeTab={currentTab}
                                onClick={setProjectViewTab}
                            />
                        </NonMvpItem>
                    </S.BtnBlock>
                    <div>
                        <TabsBlock value={1} activeTab={currentTab}>
                            <WrapperOffset offsetTop="16">
                                <div className="row mt-1">
                                    <div className="col-2">
                                        <VerticalTabBtn
                                            id="workReleaseVerticalTab"
                                            data-value={1}
                                            activeTab={stageTabItem}
                                            onClick={(e) =>
                                                setStageTabItem(e.currentTarget.dataset.value)
                                            }
                                        >
                                            <BtnTitle>Выдача в работу</BtnTitle>
                                            {jobCount > 0 ? (
                                                <BtnSubtitle>Задачи в процессе</BtnSubtitle>
                                            ) : (
                                                <BtnSubtitle>Задач нет</BtnSubtitle>
                                            )}
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={2}
                                            activeTab={stageTabItem}
                                            disabled
                                        >
                                            <BtnTitle>Доступ на объект</BtnTitle>
                                            <BtnSubtitle>Задач нет</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={3}
                                            activeTab={stageTabItem}
                                            disabled
                                        >
                                            <BtnTitle>ПИР</BtnTitle>
                                            <BtnSubtitle>Задач нет</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            id="additionalAgreementVerticalTab"
                                            data-value={4}
                                            activeTab={stageTabItem}
                                            onClick={(e) =>
                                                setStageTabItem(e.currentTarget.dataset.value)
                                            }
                                        >
                                            <BtnTitle>Доп. соглашения</BtnTitle>
                                            <BtnSubtitle>Задачи в процессе</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={5}
                                            activeTab={stageTabItem}
                                            disabled
                                        >
                                            <BtnTitle>Логистика</BtnTitle>
                                            <BtnSubtitle>Задач нет</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={6}
                                            activeTab={stageTabItem}
                                            disabled
                                        >
                                            <BtnTitle>СМР</BtnTitle>
                                            <BtnSubtitle>Задач нет</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={7}
                                            activeTab={stageTabItem}
                                            disabled
                                        >
                                            <BtnTitle>Сдача в эксплуатацию</BtnTitle>
                                            <BtnSubtitle>Задач нет</BtnSubtitle>
                                        </VerticalTabBtn>
                                        <VerticalTabBtn
                                            data-value={8}
                                            activeTab={stageTabItem}
                                            hide={!CompletedWorksTabVisible}
                                            onClick={(e) =>
                                                setStageTabItem(e.currentTarget.dataset.value)
                                            }
                                        >
                                            <BtnTitle>{COMPLETED_WORKS_TAB_TITLE}</BtnTitle>
                                            <BtnSubtitle>Задачи в процессе</BtnSubtitle>
                                        </VerticalTabBtn>
                                    </div>
                                    <div className="col-10">
                                        {projectId && +stageTabItem === 1 && (
                                            <JobTab
                                                projectId={projectId}
                                                preliminary_organization={
                                                    projectData?.preliminary_organization
                                                }
                                                projectExtId={projectData?.project_ext_id}
                                            />
                                        )}

                                        {projectId && +stageTabItem === 4 && (
                                            <AdditionalAgreementsTab
                                                activeTab={stageTabItem}
                                                projectId={projectId}
                                                projectNumber={projectData?.project_ext_id}
                                            />
                                        )}

                                        {projectId && +stageTabItem === 8 && (
                                            <CompletedWorksTab projectId={projectId} />
                                        )}
                                    </div>
                                </div>
                            </WrapperOffset>
                        </TabsBlock>
                        {documentListVisible && (
                            <TabsBlock value={2} activeTab={currentTab}>
                                <DocumentTable />
                            </TabsBlock>
                        )}
                        <TabsBlock value={3} activeTab={currentTab}>
                            test test
                        </TabsBlock>
                    </div>
                </WrapperOffset>
            </WrapperOffset>
        </CommonLayout>
    );
};
