import styled from 'styled-components/macro';

export const RoundedCard = styled.div<{width: string}>`
    min-width: ${(props) => props.width};
    height: 200px;
    padding: 24px;
    flex-direction: column;
    display: flex;
    gap: 24px;
    border: 1px solid var(--color-divider-grey);
    border-radius: var(--size-border-radius-x6);
`;
