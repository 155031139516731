import {TpiSpecification} from 'pages/VirSelect/types';
import {
    AdditionalAgreementFull,
    IAgreementWorkObject,
    IAgreementWorkSpecification,
    IAgreementWorksheet,
    IComment,
    IWorkCoefficient,
} from 'pages/DSPage/types';
import {TAttachmentFile} from 'shared/types/FileTypes';
import {IStatus} from 'shared/types/statusTypes';

export type TCopyingAdditionalAgreement = {id: string; number_agreement: string};

export enum EAdditionalAgreementType {
    Agreement = 1,
    Addition,
    Termination,
}

export type TAdditionalAgreementCopy = AdditionalAgreementFull;

export enum EWorkObjectTypes {
    BS = 1,
    MAIN_PART = 2,
    RESPONSE_PART = 3,
}

export interface IUpdatedAgreement {
    agreement_number?: string;
    number_addendum?: number;
    parent_signing_date: string | null;
    signing_date?: string | null;
    id?: string;
    status?: IStatus;
    sum?: number;
    sum_tax?: number;
    total_sum?: number;
    is_released?: boolean;
    is_prepayment: boolean;
    is_termination?: boolean;
    project_number?: string;
    contract_name?: string;
    agreement_id: string;
    project_id?: string;
    project?: string;
    contract_id: string;
    contract?: string;
    tpi_id: string;
    remote_territory_id?: string;
    begin_date: string | null;
    end_date: string | null;
    default_begin_date?: string | null;
    default_end_date?: string | null;
    update_action?: 'create' | 'update' | 'delete' | 'nothing';
    works: Record<string, ConstructorVirType>;
    agreement_comments: Array<{comment: string}>;
    agreement_attachments: TAttachmentFile[];
    meta: string;
}

export interface ConstructorVirType {
    id: string;
    sum: number;
    sum_tax: number;
    total_sum: number;
    appendix_number?: string;
    update_action?: 'new' | 'update' | 'delete' | 'nothing';
    work_objects: Record<string, ConstructorVirWorkObject>;
}

export interface ConstructorVirWorkObject {
    virId?: string;
    work_type_id: string;
    update_action?: 'new' | 'update' | 'delete' | 'nothing';
    object_type: EWorkObjectTypes;
    object_name: string;
    object_id: string;
    transport_net_id?: string;
    is_half_set?: boolean;
    id?: string;
    gfk?: string;
    name?: string;
    address?: string;
    lav_gfk?: string;
    work_specifications: Record<string, TpiSpecification>;
}

export interface WorkCoefficientType {
    id: string;
    is_manual?: boolean;
    tpi_specification_id?: string;
    number?: string;
    name?: string;
    note?: string;
    coefficient: number;
    tpi_specification?: string;
    type?: number;
}

export interface WorkCoefficientTypeNullish {
    id: string;
    is_manual?: boolean;
    tpi_specification_id?: string;
    number?: string;
    name?: string;
    note?: string;
    coefficient: number | null;
}

export interface CreateAdditionalAgreementRequestType {
    is_released: boolean;
    agreement_id: string;
    is_prepayment: boolean;
    project_id: string;
    contract_id: string;
    tpi_id: string;
    remote_territory_id?: string;
    begin_date: string | null;
    end_date: string;
    signing_date?: string;
    parent_signing_date?: string;
    sum: number;
    sum_tax: number;
    total_sum: number;
    works: MainWorkObjectFromConstructorRequestType[];
    agreement_comments: Array<Partial<{comment: string; appendix_number: string}>>;
    agreement_attachments: TAttachmentFile[];
    meta: string;
}

export interface MainWorkObjectFromConstructorRequestType {
    sum: number;
    sum_tax: number;
    total_sum: number;
    appendix_number: string;
    work_objects: VirWorkFromConstructorRequestType[];
}

export interface VirWorkFromConstructorRequestType {
    work_type_id: string;
    object_type: EWorkObjectTypes;
    transport_net_id?: string;
    is_half_set?: boolean;
    work_specifications: WorkSpecificationRequestType[];
}

export interface WorkSpecificationRequestType {
    tpi_specification_id: string;
    number: string;
    name: string;
    note: string;
    unit: string;
    price: number;
    coefficient: number;
    coefficient_ams: number;
    coefficient_foundation: number;
    begin_date: string | null;
    end_date: string | null;
    is_ams: boolean;
    is_foundation: boolean;
    coefficient_remote_territory: number;
    ordinal_number: number;
    quantity: number;
    is_agreed_price: boolean;
    master_position?: string | null;
    sum: number;
    sum_tax: number;
    total_sum: number;
    work_coefficients: Omit<WorkCoefficientType, 'id'>[];
    work_specification_comments: Array<Partial<IComment> & Pick<IComment, 'comment'>>;
}

export interface IAdditionalAgreementListItem {
    addendum: IAgreementAddition[];
    agreement_id: string | null;
    approved_at: string | null;
    bs_gfk: string;
    bs_name: string;
    bs_number: string;
    contract_number: string;
    created_at: string;
    id: string;
    implementer_name: string;
    is_termination: boolean;
    number_addendum: number | null;
    number_agreement: string;
    project_ext_id: string;
    project_id: string;
    project_pos_code: string;
    purchase_lots_name: string;
    purchasing_event_number: string;
    signing_date: string | null;
    status: IStatus;
    work_types: string;
}

export interface IAgreementAddition {
    addendum: IAgreementAddition[];
    agreement_id: string | null;
    approved_at: string | null;
    bs_gfk: string;
    bs_name: string;
    bs_number: string;
    contract_number: string;
    created_at: string;
    id: string;
    implementer_name: string;
    is_termination: boolean;
    number_addendum: number | null;
    number_agreement: string;
    project_ext_id: string;
    project_id: string;
    project_pos_code: string;
    purchase_lots_name: string;
    purchasing_event_number?: string;
    framework_contract_number?: string;
    signing_date: string | null;
    status: IStatus;
    work_types: string;
}

export interface AdditionalAgreementsParams {
    page?: number;
    page_size?: number;
    search?: string;
    status_id?: string;
    regions?: string[];
    branches?: string[];
    implementers?: string[];
    purchasing_events?: string[];
    contracts?: string[];
    approving_at?: string;
    project_id?: string;
}

export interface IToggledAdditionalAgreementListItem extends IAdditionalAgreementListItem {
    isOpen?: boolean;
    toggleOpenDetails?: (id: string) => void;
}

export interface ICopingAdditionalAgreement {
    id: string;
    number: string;
    addendum_number: number | null;
    created_at: string;
    bs_name: string;
    work_types: string;
    branch: string;
    addendum: ICopingAdditionalAgreement[] | null;
}

export interface ITemporaryCopingAgreement
    extends Pick<
        IUpdatedAgreement,
        | 'agreement_id'
        | 'contract'
        | 'contract_id'
        | 'tpi_id'
        | 'begin_date'
        | 'end_date'
        | 'agreement_comments'
        | 'agreement_attachments'
        | 'meta'
        | 'project_id'
        | 'project_number'
        | 'contract_name'
        | 'remote_territory_id'
        | 'is_released'
        | 'is_prepayment'
    > {
    id: string;
    tpi: {id: string; name: string};
    works: TTemporaryCopingWork[];
}

export type TOmittedCopingWork = Omit<IOmittedWorksheet, 'sum' | 'sum_tax' | 'total_sum'>;

export interface TTemporaryCopingWork extends IAgreementWorksheet {
    work_objects: ITemporaryAgreementWorkObject[];
    checked: boolean;
}

export interface ITemporaryAgreementWorkObject extends IAgreementWorkObject {
    objectId?: string;
    work_specifications: ITemporaryAgreementWorkSpecification[];
    checked: boolean;
    disabled: boolean;
}

// интерфейсы объекта ДС для копирования пунктов
export interface ITemporaryAgreementWorkSpecification extends IAgreementWorkSpecification {
    checked: boolean;
    disabled: boolean;
    type_position: number;
}

export interface IOmittedAgreementWorkSpecification extends IAgreementWorkSpecification {
    tpi_specification_new: Omit<
        IAgreementWorkSpecification,
        | 'work_specification_attachments'
        | 'work_coefficients'
        | 'quantity'
        | 'sum'
        | 'sum_tax'
        | 'total_sum'
    >;
    work_coefficients_new: IWorkCoefficient[];
}

export interface IOmittedAgreementWorkObject extends IAgreementWorkObject {
    work_specifications: IOmittedAgreementWorkSpecification[];
}

export interface IOmittedWorksheet extends IAgreementWorksheet {
    work_objects: IOmittedAgreementWorkObject[];
}

export interface IAgreementCopingWorksheet {
    id: string;
    tpi: {id: string; name: string};
    works: TOmittedCopingWork[];
}

export interface IAdditionalAgreementResponse {
    id: string;
    errors: IAdditionalAgreementError[];
}

export interface IAdditionalAgreementError {
    attribute: {
        name: string;
        value: Array<{id: string; value: string}>;
    } | null;
    code: string;
    description: string;
    id: string;
    is_fixed: boolean;
    how_to_fix: string;
    hyper_link: {
        name: string | null;
        value: string | null;
    };
    type: string;
}

export class AgreementError {
    private _objectError: string;
    private _endDateError: string;

    constructor() {
        this._objectError = '';
        this._endDateError = '';
    }

    public get objectError() {
        return this._objectError;
    }

    public set objectError(error: string) {
        this._objectError = error;
    }

    public get endDateError() {
        return this._endDateError;
    }

    public set endDateError(error: string) {
        this._endDateError = error;
    }

    allErrors(): string {
        return `${this.objectError !== '' ? `${this.objectError}\n` : ''}${this.endDateError}`;
    }
}
