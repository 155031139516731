import {EAttachmentType} from 'shared/types/FileTypes';
import {ICompletedWorksDocument, TClosingDocumentInfo} from 'shared/types/completedWorksTypes';
import {formatDateToBackendFormat} from 'shared/helpers/formatHelper';
import {TTaskList} from 'shared/types/tasksTypes';

import {attachmentFetched} from './index';

export enum ECompletedWorksView {
    normal,
    kzd,
    po,
    mandatoryPo,
}

export interface PoNumberCreationProps {
    id: string;
    taskData: TTaskList;
    handleClose: () => void;
    mode: ECompletedWorksView;
}

export type TOutputClosingDocuments = {id: string; index: number; data: TClosingDocumentInfo[]};

export const handleDownloadAttachment = (fileId: string, fileName: string, type: EAttachmentType) =>
    attachmentFetched({
        fileId,
        fileName,
        type,
    });

export const completedWorksHasCurrentSpecs = (
    data: ICompletedWorksDocument | undefined,
): boolean => {
    if (!data) return false;
    return data.works.some((work) =>
        work.work_objects.some((object) => object.specifications.vvr_current.length > 0),
    );
};

export const mapInputClosingDocuments = (data?: TClosingDocumentInfo[]): TClosingDocumentInfo[] => {
    return (
        data?.map((item) => ({
            doc_type: item.doc_type,
            doc_date: item.doc_date ?? formatDateToBackendFormat(new Date(Date.now()).toString()),
            doc_number: item.doc_number ?? '',
        })) ?? []
    );
};

export const mapOutputClosingDocuments = (
    id: string | undefined,
    index: number,
    store: any,
    invoiceDataShowed: boolean,
): TOutputClosingDocuments => {
    const data = [
        {
            doc_type: 'ks_2',
            doc_date: formatDateToBackendFormat(store.ks2Date),
            doc_number: store.ks2DocNumber,
        },
        {
            doc_type: 'ks_3',
            doc_date: formatDateToBackendFormat(store.ks3Date),
            doc_number: store.ks3DocNumber,
        },
    ];
    if (invoiceDataShowed)
        data.push({
            doc_type: 'sf',
            doc_date: formatDateToBackendFormat(store.invoiceDate),
            doc_number: store.invoiceDocNumber,
        });
    return {
        id: id ?? '',
        index: index,
        data: data,
    };
};
