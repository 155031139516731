import React from 'react';
import {useStore} from 'effector-react';
import {Typography} from '@beeline/design-system-react';

import {$createVirStartPageData} from 'pages/VirStart/model';
import {WorkObjectSelect} from 'pages/VirStart/ui/VirStartData/WorkObjectSelect';
import {ProjectSelect} from 'pages/VirStart/ui/VirStartData/ProjectSelect';
import ProjectInfoCell from 'shared/ui/deprecated/ProjectInfoCell/ProjectInfoCell';
import Row from 'shared/ui/deprecated/Row/Row';

import {WorkTypeSelect} from './WorkTypeSelect';
import {ContractSelect} from './ContractSelect';
import {TpiSelect} from './TpiSelect';
import * as S from './style';

export function VIRData() {
    const {selectedProject, fromProject, selectedObject} = useStore($createVirStartPageData);

    const title = fromProject ? 'Проверьте данные и укажите тип работ:' : 'Выберите проект';
    return (
        <S.Wrapper>
            <Typography variant="h4">{title}</Typography>

            {!fromProject && <ProjectSelect />}

            {(fromProject || selectedProject) && (
                <S.VIRDataStyled>
                    <Row gutterX="24">
                        <WorkObjectSelect />
                        <WorkTypeSelect />
                        <div className="col-3">
                            {selectedObject?.gfk && (
                                <ProjectInfoCell title="Код ГФК">
                                    {selectedObject?.gfk}
                                </ProjectInfoCell>
                            )}
                        </div>
                        <div className="col-3">
                            {selectedObject?.pos_code && (
                                <ProjectInfoCell title="Номер позиции">
                                    {selectedObject?.pos_code}
                                </ProjectInfoCell>
                            )}
                        </div>
                        <ContractSelect />
                        <TpiSelect />
                    </Row>
                </S.VIRDataStyled>
            )}
        </S.Wrapper>
    );
}
