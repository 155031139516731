import styled from 'styled-components/macro';

export const SubText = styled.span`
    margin-top: 4px;
    display: block;
    color: var(--bs-gray-600);
`;

export const MainText = styled.div`
    font-size: 17px;
    line-height: 18px;
`;

export const SelectWrapper = styled.div`
    margin-bottom: 36px;
`;
