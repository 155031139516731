import React from 'react';
import {useStore} from 'effector-react';
import Select, {SelectItem} from 'shared/ui/Select/Select';
import {$createVirStartPageData, $createVirStartPageOptions, selectWorkType} from '../../../model';
import * as S from './styled';

export function WorkTypeSelect() {
    const {workTypeOptions, formErrors, loadingCatalogWorkTypes} = useStore(
        $createVirStartPageOptions,
    );
    const {selectedWorkType} = useStore($createVirStartPageData);

    return (
        <S.SelectWrapper className="col-6">
            <Select
                value={selectedWorkType || []}
                valueLabel="work_types_name"
                handleEdit={selectWorkType}
                title="Тип работ"
                disabled={loadingCatalogWorkTypes}
                error={formErrors?.workType}
                hint={formErrors?.workType ? 'Укажите тип работ' : ''}
                id="AgreementConstructorWorkSelect"
            >
                {workTypeOptions.map((item) => (
                    <SelectItem
                        selected={selectedWorkType}
                        multiple={false}
                        key={item.work_types_id}
                        value={item}
                        id={item.work_types_name}
                    >
                        {item.work_types_name}
                    </SelectItem>
                ))}
            </Select>
        </S.SelectWrapper>
    );
}
