import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useGate, useStore} from 'effector-react';
import {
    Button,
    Icon,
    Skeleton,
    TableBody,
    TableRow,
    Typography,
} from '@beeline/design-system-react';
import {Icons} from '@beeline/design-tokens/js/iconfont';

import {TableHeader} from 'Features/TableHeader';
import {TableBase} from 'Entities/TableBase';
import {TableBodySkeleton} from 'Entities/skeletons/TableBodySkeleton';
import WrapperFlex from 'shared/ui/Wrappers/WrapperFlex';
import {CustomTableData} from 'shared/styles/commonStyle';
import {IPaginationData} from 'shared/types/commonTypes';
import {TJobListItem} from 'shared/types/jobsTypes';
import {$isUserVK, $userActions} from 'shared/model/user';
import {EAccessActions} from 'shared/const/actions';

import * as S from '../style';
import {jobListColumns} from './columns';
import {
    $jobTableStore,
    jobPageChanged,
    jobPageSizeChanged,
    JobTabGate,
} from '../../../model/jobTab';
import {VerticalTabBlock} from '../../ProjectViewVerticalTabs/ProjectViewVerticalTabs';

interface TasksTableProps {
    projectId?: string;
    preliminary_organization?: string[];
    projectExtId?: number;
}

export const JobTab = ({projectId, preliminary_organization, projectExtId}: TasksTableProps) => {
    useGate(JobTabGate, projectId);
    const navigate = useNavigate();
    const isUserVK = useStore($isUserVK);
    const actions = useStore($userActions);
    const {params, total, tableData, isLoading} = useStore($jobTableStore);

    const handleNavigate = (id: string) => {
        navigate(`/jobs/${id}`);
    };

    const handleCreateJob = () => {
        projectId &&
            navigate(`/projects/${projectId}/job-creation`, {
                state: {
                    preliminary_organization:
                        (preliminary_organization && preliminary_organization[0]) ?? '',
                    projectExtId: projectExtId ?? '',
                },
            });
    };

    const createButtonVisible = isUserVK && actions.includes(EAccessActions.JobTaskCreation);
    const filteredColumns = jobListColumns.filter((column) =>
        isUserVK ? true : column.name !== 'contractor',
    );
    const tableIsEmpty = !isLoading && total === 0;

    const paginationData: IPaginationData = {
        ...params,
        total,
        setPage: jobPageChanged,
        setPageSize: jobPageSizeChanged,
        isLoading,
    };
    return (
        <VerticalTabBlock data-value={1} activeTab={1}>
            <WrapperFlex flexDirection="column" alignItems="flex-start" gap="16px">
                <Typography variant="h5">Выдача в работу</Typography>
                {createButtonVisible &&
                    (!isLoading || total > 0 ? (
                        <Button size="medium" onClick={handleCreateJob}>
                            Создать задание
                        </Button>
                    ) : (
                        <Skeleton width={169} height={48} variant="square" />
                    ))}

                {tableIsEmpty ? (
                    <S.EmptyWrapper>
                        <Icon iconName={Icons.PageEmpty} color="blue" size="medium" contained />
                        <S.StyledTypography variant="h6" margin="24px 0 8px">
                            Выданных заданий еще нет
                        </S.StyledTypography>
                        {createButtonVisible && (
                            <>
                                <S.StyledTypography
                                    variant="body2"
                                    className="inactiveText"
                                    margin="0 0 24px"
                                >
                                    Вы можете создать его, нажав на кнопку ниже
                                </S.StyledTypography>
                                <Button className="mb-3" size="medium" onClick={handleCreateJob}>
                                    Создать задание
                                </Button>
                            </>
                        )}
                    </S.EmptyWrapper>
                ) : (
                    <TableBase minTableWidth="1050px" paginationData={paginationData}>
                        <TableHeader<TJobListItem> columns={filteredColumns} />
                        {isLoading ? (
                            <TableBodySkeleton<TJobListItem>
                                pageSize={params.pageSize}
                                columns={filteredColumns}
                                height="52px"
                                dense
                            />
                        ) : (
                            <TableBody>
                                {tableData.map((item) => (
                                    <TableRow
                                        key={item.id}
                                        onClick={() => handleNavigate(item.id)}
                                        hover
                                    >
                                        {filteredColumns.map((column) => (
                                            <CustomTableData
                                                key={`${item.id}_${column.name}`}
                                                width={column.width}
                                                height="52px"
                                                dense
                                            >
                                                {column.render && column.render(item)}
                                            </CustomTableData>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </TableBase>
                )}
            </WrapperFlex>
        </VerticalTabBlock>
    );
};
