import {CheckboxType} from '@beeline/design-system-react/types/components/Checkbox/Checkbox.types';

import {EWorkObjectTypes} from './additionalAgreementsTypes';
import {TMeta} from './commonTypes';
import {TAttachmentFile} from './FileTypes';
import {IEmployeeInfo} from './userTypes';
import {TBaseStation} from './baseStationsTypes';
import {IShortStatus, IStatus} from './statusTypes';
import {IPurchasingEvent} from './purchasesTypes';
import {ContractorType} from './ContractorTypes';
import {IComment, IWorkCoefficient} from '../../pages/DSPage/types';

export interface ICompletedWorksListItem {
    gpo_short_name: string;
    created_at: string;
    id: string;
    status: IShortStatus;
    number: number;
    sum: string;
    sum_tax: string;
    total_sum: string;
    updated_at: string;
    approved_at: string | null;
}

export interface ICompletedWorksListResponse {
    id: string;
    number_agreement: string;
    vvrs: ICompletedWorksListItem[];
}

export interface IToggledCompletedListItem extends ICompletedWorksListResponse {
    isOpen?: boolean;
    toggleOpenDetails?: (id: string) => void;
}

export interface IColumnCompletedWorksListItem extends ICompletedWorksListItem {
    hasPermission?: boolean;
}

export interface ICompletedWorksListResponse {
    id: string;
    number_agreement: string;
    vvrs: ICompletedWorksListItem[];
}

export interface IToggledCompletedListItem extends ICompletedWorksListResponse {
    isOpen?: boolean;
    toggleOpenDetails?: (id: string) => void;
}

export interface IColumnCompletedWorksListItem extends ICompletedWorksListItem {
    hasPermission?: boolean;
}

export interface ICompletedWorksAgreement {
    agreement: IAgreementShortDataForCompletedWorks;
    id: string;
    number: string;
    addendum_number: number | null;
    bs_name: string;
    project: {
        id: string;
        project_ext_id: number;
    };
    contract: {
        id: string;
        number: string;
    };
    ds_attachments: TAttachmentFile[];
    works: ICompletedWorksSheet[];
}

export interface ICompletedWorksSheet {
    id: string;
    appendix_number: number;
    work_objects: ICompletedWorksObject[];
}

export interface ICompletedWorksObject {
    id: string;
    gfk: string;
    name: string;
    object_type: EWorkObjectTypes;
    closed: boolean;
    is_half_set: boolean;
    lav_gfk?: string;
    work_specifications: IViewCompletedSpecificationObject;
}

export interface ICompletedWorksSpecification {
    accuracy: number;
    category_name: string | null;
    coefficient: string;
    coefficient_ams: string;
    coefficient_foundation: string;
    coefficient_remote_territory: string;
    id: string;
    is_agreed_price: boolean;
    is_ams: boolean;
    is_category: boolean;
    is_foundation: boolean;
    is_prepayment: boolean;
    is_quantity_editable?: boolean;
    link_document_id?: string | null;
    master_position: string;
    name: string;
    note: string | null;
    number: string;
    ordinal_number: number;
    parent_name: string | null;
    price: string;
    result: TCompletedWorksSpecAction;
    quantity: string;
    quantity_completed: number;
    quantity_full: number;
    quantity_max: number;
    sum: string;
    sum_tax: string;
    total_sum: string;
    tpi_specification: string;
    unit: string;
    vvr_number: string;
    work_coefficients: IWorkCoefficient[];
    work_detail: {id: string; work_category: string} | null;
    completed_work_specification_comment: string | null;
    completed_work_specification_comments: IComment[];
    completed_work_specification_attachments: TAttachmentFile[];
    work_specification_attachments: TAttachmentFile[];
    work_specification_comments: IComment[];
}

export type TBackendCompletedWorksSpecification = Pick<
    ICompletedWorksSpecification,
    | 'completed_work_specification_comment'
    | 'completed_work_specification_attachments'
    | 'master_position'
    | 'quantity'
    | 'price'
    | 'sum'
    | 'sum_tax'
    | 'total_sum'
    | 'result'
> & {work_specification: string};

export type TBackendCompletedWorksObject = Pick<TAdaptedCompletedWorksObject, 'id'> & {
    completed_work_specifications: TBackendCompletedWorksSpecification[];
};

export type TBackendAdaptedCompletedWorksSheet = Pick<TAdaptedCompletedWorksSheet, 'id'> & {
    work_objects: TBackendCompletedWorksObject[];
};

export interface IAdaptedCompletedWorksSpecification extends ICompletedWorksSpecification {
    checked: boolean;
    mode: TCompletedWorkSpecificationMode;
}

export type TAdaptedCompletedWorksObject = Omit<ICompletedWorksObject, 'work_specifications'> & {
    work_specifications: IAdaptedCompletedWorksSpecification[];
    completed_work_specifications: IAdaptedCompletedWorksSpecification[];
    finished_work_specifications: IAdaptedCompletedWorksSpecification[];
};

export type TAdaptedCompletedWorksSheet = Omit<ICompletedWorksSheet, 'work_objects'> & {
    work_objects: TAdaptedCompletedWorksObject[];
    checked: boolean;
    checkboxType: CheckboxType;
};

export type TAdaptedCompletedWorksAgreement = Omit<ICompletedWorksAgreement, 'works'> & {
    agreement: IAgreementShortDataForCompletedWorks;
    completed_works_id?: string;
    works: TAdaptedCompletedWorksSheet[];
    meta: TMeta;
    completed_work_comment: string | null;
    completed_work_attachments: TAttachmentFile[];
};

export type TBackendCompletedWorksAgreement = {
    agreement_id: string;
    project_id: string;
    contract_id: string;
    works: TBackendAdaptedCompletedWorksSheet[];
    meta: TMeta;
    completed_work_comment: string | null;
    completed_work_attachments: TAttachmentFile[];
    sum: number;
    sum_tax: number;
    total_sum: number;
};

export type TCompletedWorksSpecAction = null | ESpecificationAction;

export enum ESpecificationAction {
    DONE = 1,
    NOTHING = 2,
}

export interface IAgreementShortDataForCompletedWorks {
    id: string;
    agreement_number: string;
    sum: string;
    sum_tax: string;
    total_sum: string;
}

export interface ICompletedWorksDocument {
    agreement: IAgreementShortDataForCompletedWorks;
    approved_at: string | null;
    completed_work_attachments: TAttachmentFile[];
    completed_work_comments: IComment[];
    contract_id: string;
    contract_name: string;
    contractor: ContractorType;
    created_at: string;
    creator: IEmployeeInfo;
    ds_attachments: TAttachmentFile[];
    editor: IEmployeeInfo;
    gpo_short_name: string;
    id: string;
    need_update_agreement: boolean;
    number: number;
    project: TBaseStation;
    purchase_lots_name: string;
    purchasing_event: IPurchasingEvent;
    status: IStatus;
    sum: string;
    sum_tax: string;
    total_sum: string;
    updated_at: string;
    works: ICompletedWork[];
}

export interface ICompletedWork {
    id: string;
    work_objects: ICompletedWorkObject[];
}

export interface ICompletedWorkObject {
    id: string;
    gfk: string;
    name: string;
    object_type: EWorkObjectTypes;
    specifications: IViewCompletedSpecificationObject;
}

export interface IViewCompletedSpecificationObject {
    ds: ICompletedWorkSpecification[];
    vvr_current: ICompletedWorkActiveSpecification[];
    vvr_other: ICompletedWorkActiveSpecification[];
}

export interface ICompletedWorkSpecification {
    accuracy: number;
    category_name: string | null;
    coefficient: string;
    coefficient_ams: string;
    coefficient_foundation: string;
    coefficient_remote_territory: string;
    completed_work_specification_attachments: TAttachmentFile[];
    completed_work_specification_comments: IComment[];
    id: string;
    is_agreed_price: boolean;
    is_ams: boolean;
    is_category: boolean;
    is_foundation: boolean;
    is_prepayment: boolean;
    is_quantity_editable: boolean;
    link_document_id: string | null;
    master_position: string;
    name: string;
    note: string | null;
    number: string;
    ordinal_number: number | null;
    parent_name: string | null;
    price: string;
    quantity_completed: number;
    quantity_full: number;
    quantity_max: number;
    result: TCompletedWorksSpecAction;
    sum: string;
    sum_tax: string;
    total_sum: string;
    tpi_specification: string;
    unit: string;
    vvr_number: string;
    work_coefficients: IWorkCoefficient[];
    work_detail: {
        id: string;
        work_category: string;
    } | null;
    work_specification_attachments: TAttachmentFile[];
    work_specification_comments: IComment[];
    work_specification_approval?: boolean | null;
    work_specification_approval_total?: boolean | null;
}

export interface ICompletedWorkActiveSpecification extends ICompletedWorkSpecification {
    work_specification_approval: boolean | null;
    work_specification_approval_total: boolean | null;
}

// интерфейсы для преобразованного ВВР на фронте
export type TAdaptedCompletedWorksDocument = Omit<ICompletedWorksDocument, 'works'> & {
    works: TAdaptedCompletedWork[];
};
export type TAdaptedCompletedWork = {
    id: string;
    work_objects: TAdaptedWorkObject[];
};

export interface TAdaptedWorkObject {
    id: string;
    gfk: string;
    name: string;
    completed_works_specifications: TAdaptedCompletedWorkSpecification[];
    closed_works_specifications: TAdaptedCompletedWorkSpecification[];
    available_works_specifications: TAdaptedCompletedWorkSpecification[];
}

export interface TAdaptedCompletedWorkSpecification extends ICompletedWorkSpecification {
    mode: TCompletedWorkSpecificationMode;
}

export type TCompletedWorkSpecificationMode = 'current' | 'closed' | 'available';

export type TClosingDocumentInfo = {
    doc_date: string;
    doc_number: string;
    doc_type: string;
};
