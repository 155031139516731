import React from 'react';
import {DatePicker} from '@beeline/design-system-react';
import {DateValue} from '@beeline/design-system-react/types/components/DatePicker/DatePicker.types';

import {getFutureDate} from 'shared/helpers/dates';
import {EAdditionalAgreementType} from 'shared/types/additionalAgreementsTypes';

import * as S from './style';
import {formatDateToBackendFormat} from '../../../helpers/formatHelper';

interface DatePickerCellProps {
    dates: {
        minDate: string | null;
        date: string | null;
        default_date?: string | null;
    };
    updateDatesStore?: (dates: DateValue) => void;
    isBegin: boolean;
    agreementType: EAdditionalAgreementType | null;
}

export function DatePickerCell({
    agreementType,
    dates,
    updateDatesStore,
    isBegin,
}: DatePickerCellProps) {
    const {date, minDate, default_date} = dates;

    const onSelectDate = (date: DateValue) => {
        const result = date as string;
        updateDatesStore?.([result, default_date || '']);
    };
    const value = date ? new Date(date).toISOString() : undefined;

    const minDatepickerDate = (function () {
        switch (agreementType) {
            case EAdditionalAgreementType.Agreement: {
                return formatDateToBackendFormat(getFutureDate(10).toISOString());
            }
            case EAdditionalAgreementType.Addition: {
                return formatDateToBackendFormat(new Date(minDate ?? Date.now()).toISOString());
            }
            default: {
                return undefined;
            }
        }
    })();

    const maxDate = getFutureDate(
        0,
        agreementType === EAdditionalAgreementType.Agreement ? 3 : 5,
    ).toISOString();
    return (
        <S.Wrapper>
            <DatePicker
                helperText=""
                minDate={minDatepickerDate}
                maxDate={maxDate}
                onChange={onSelectDate}
                value={value}
            />
        </S.Wrapper>
    );
}
